import React, { useRef } from 'react'
import { Modal } from 'antd-mobile'
import { useBodyScrollLock } from '../hooks/useBodyScrollLock'

const { alert, prompt } = Modal

export function showConfirm(title, content, callback) {
  alert(title, content, [{ text: '取消' }, { text: '确定', onPress: callback }])
}

export function showPrompt(title, content, callback) {
  prompt(
    title,
    content,
    [{ text: '取消' }, { text: '提交', onPress: callback }],
    'default'
  )
}

export function ShowEditModal(props) {
  const {
    visible,
    title,
    formContent,
    handleCancel,
    afterClose,
    popupVal,
    anType,
    transparent,
    closable = true,
  } = props
  const modalRef = useRef(null)

  useBodyScrollLock(visible, modalRef.current)

  return (
    <Modal
      ref={modalRef}
      popup={popupVal}
      visible={visible}
      title={title}
      transparent={transparent}
      onClose={handleCancel}
      animationType={anType}
      afterClose={afterClose}
      closable={closable}
    >
      {formContent}
    </Modal>
  )
}

export function ShowSimpleContent(props) {
  const { visible, formContent, handleCancel, animated = true } = props
  const modalRef = useRef(null)

  useBodyScrollLock(visible, modalRef.current)

  return (
    <Modal
      ref={modalRef}
      transparent
      animated={animated}
      visible={visible}
      onClose={handleCancel}
    >
      {formContent}
    </Modal>
  )
}
