import type { SWRConfiguration } from 'swr'

import { useWechatHideAllNonBaseMenuItem } from '../contexts/wechat'
import type { BranchInfo } from '../api'
import { getBranchInfo, getBranchToken } from '../api'
import { branchTokenHandler } from '../utility/token'
import { useUser } from './useUser'

export function useBranchInfo(options?: SWRConfiguration) {
  useWechatHideAllNonBaseMenuItem()

  return useUser<BranchInfo>({
    tokenKey: branchTokenHandler.key,
    getToken: (code: string) => getBranchToken(code).then(({ Key }) => Key),
    getUser: getBranchInfo,
    onSuccess: (data) => {
      localStorage.setItem('shop_id', data.shop_id)
      localStorage.setItem('branch_id', data.branch_id)
    },
    swrOptions: options,
  })
}
