import React, { useMemo, useState } from 'react'
import { Icon } from 'antd'
import { Button, Stepper } from '../../../../components'
import './index.css'

export type SelectedListProps = {
  data: any[]
  available: number
  onNumberChange: (value: any, cartId: string) => Promise<any>
  onDelete: (cartId: string) => Promise<any>
  onConfirm: () => void
}

export function SelectedList({
  data,
  available,
  onNumberChange,
  onDelete,
  onConfirm,
}: SelectedListProps) {
  const [list, setList] = useState(data)
  const maxAll = useMemo(
    () =>
      list.map((item) => +item.goods_num).reduce((a, b) => a + b, 0) +
      available,
    [data, available]
  )

  const availableNum = useMemo(() => {
    const selectedNum = list
      .map((item) => +item.goods_num)
      .reduce((a, b) => a + b, 0)
    return maxAll - selectedNum
  }, [list, maxAll])

  function handleNumChange(value: any, cartId: string) {
    onNumberChange(value, cartId).then(() => {
      setList((prevList) =>
        prevList.map((item) =>
          item.cart_id === cartId ? { ...item, goods_num: value } : item
        )
      )
    })
  }

  function handleItemDelete(cartId: string) {
    onDelete(cartId).then(() => {
      setList((prevList) => prevList.filter((item) => item.cart_id !== cartId))
    })
  }

  return (
    <>
      <div className="selected-list-header">查看已选商品</div>
      <div className="selected-list-thead">
        <span className="selected-list-spec">已选规格</span>
        <span className="selected-list-num">件数</span>
        <span className="selected-list-action">删选</span>
      </div>
      <div className="selected-list-tbody">
        {list.length > 0 ? (
          list.map((goods: any) => (
            <SelectedListItem
              key={goods.goods_id}
              {...goods}
              available={availableNum}
              onChange={handleNumChange}
              onDelete={handleItemDelete}
            />
          ))
        ) : (
          <div className="selected-list-empty">暂无商品</div>
        )}
      </div>
      <div className="selected-list-confirm">
        <Button size="large" isFull onClick={onConfirm}>
          确定
        </Button>
      </div>
    </>
  )
}

export function SelectedListItem({
  goods_spec,
  goods_num,
  cart_id,
  available,
  onChange,
  onDelete,
}: any) {
  return (
    <div className="selected-list-tr">
      {/* 规格 */}
      <div className="selected-list-spec">
        {goods_spec.map((spec: string, i: number) => (
          <div key={i}>{spec}</div>
        ))}
      </div>
      {/* 件数加减 */}
      <Stepper
        className="selected-list-num"
        min={1}
        max={+goods_num + available}
        defaultValue={+goods_num}
        onChange={(value) => onChange(value, cart_id)}
      />
      {/* 删除 */}
      <div className="selected-list-action">
        <Icon type="delete" onClick={() => onDelete(cart_id)} />
      </div>
    </div>
  )
}
