import { useCallback, useEffect, useState } from 'react'
import iconUrl from '../../images/backTop.png'
import styles from './index.module.css'

export type BackTopProps = {
  /**
   * 滚动条滚动到多少时显示回到顶部按钮
   */
  scrollThreshold?: number
  /**
   * 按钮距离底部的距离
   */
  bottom?: number
  /**
   * 按钮距离右边的距离
   */
  right?: number
}

export function BackTop({
  scrollThreshold = 400,
  bottom = 50,
  right = 10,
}: BackTopProps) {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY
    setIsVisible(scrollTop >= scrollThreshold)
  }, [scrollThreshold])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div
      className={styles['back-top']}
      style={{
        bottom,
        right,
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
      }}
      onClick={handleClick}
    >
      <img src={iconUrl} alt="回到顶部" />
    </div>
  )
}
