import { cRequest, wxRequest } from '../utility/http-instance'
import fetch from '../utility/fetcher'
import { _t } from '../utility/track'

/**
 * 获取微信配置
 * @param url 当前页面地址
 */
export function getWechatConfig(url: string) {
  return wxRequest.post<any>('Home.Jsapi', { Url: url })
}

/**
 * C端用户登录
 * @param code 微信code
 */
export function wxLogin(code: string) {
  _t('login', { code }) // 追踪登录事件
  return wxRequest.post<{ token: string }>('Home.GetWxinfo', { code })
}

export type WxqrcodeBySerialCodeParams = {
  SerialCode: string
  Width: number
  PWD: string
}

/**
 * 根据店铺序列号获取首页的小程序码
 */
export function getWxqrcodeBySerialCode(params: WxqrcodeBySerialCodeParams) {
  return fetch<string>({
    Url: 'Home.GetWxqrcodeBySerialCode',
    ...params,
  })
}

/**
 * 根据店铺ID/分店ID获取首页的小程序码
 * @param shopId 店铺ID
 * @param branchId 分店ID
 */
export function getWxqrcodeHome(shopId: string, branchId: string = '') {
  return cRequest.post<string>('Home.GetWxqrcodeHome', {
    ShopID: shopId,
    BranchID: branchId,
  })
}
