import React from 'react'
import { Icon, Toast } from 'antd-mobile'
import { request } from '../../utility/request'
import qs from 'qs'

// 连连支付完成回调地址
const ReturnUrl = `${window.location.protocol}//${window.location.host}/order`

const Pay = (props) => {
  const [p_type, setPtype] = React.useState(0)
  const [choices, setCho] = React.useState([])
  const [nowTime, setNowTime] = React.useState(0)

  React.useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  React.useEffect(() => {
    request({
      url: 'Buy.PaymentList',
      method: 'POST',
      body: qs.stringify({
        platform: 3,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setCho(res.data)
      }
    })
  }, [])

  const _map = React.useMemo(
    () =>
      choices &&
      choices.length > 0 &&
      choices.map((item, idx) => {
        return (
          <div
            key={idx}
            style={{
              padding: 2,
              borderBottom: '1px solid #E7E7E7',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={() => setPtype(idx)}
          >
            <div style={{ display: 'flex', padding: 5 }}>
              <img
                style={{
                  width: 45,
                  height: 45,
                  padding: 5,
                  objectFit: 'contain',
                }}
                src={item.logo}
                alt=""
              />
              <p style={{ alignSelf: 'center' }}>{item.name}</p>
            </div>

            {idx === p_type ? (
              <Icon type="check-circle" color="#44baae" />
            ) : (
              <span
                style={{
                  width: 20,
                  height: 20,
                  border: '1px solid #cdcaca',
                  borderRadius: '50%',
                }}
              ></span>
            )}
          </div>
        )
      }),
    [choices, p_type]
  )

  const handlePay = React.useCallback(() => {
    setNowTime(Date.now())
    if (Date.now() - nowTime < 2000) {
      return
    }
    if (choices.length <= 0) {
      return
    }
    let _url = ''
    switch (choices[p_type]['code']) {
      case 'jpd-c-netpay':
        _url = 'Pay.WxNetPay'
        break
      case 'jpd-c':
        _url = 'Pay.Wxpay'
        break
      case 'lianlian-wxpay-h5':
        _url = 'LianlianPay.Pay'
        break
      default:
        break
    }

    request({
      url: _url,
      method: 'POST',
      body: qs.stringify({
        PayChannel: 4, // H5 连连支付
        ReturnUrl,
        Paycode: localStorage.getItem('pay_code') || null,
        Key: localStorage.getItem('c_token') || null,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        function onBridgeReady() {
          let payload = {}

          if (_url === 'Pay.WxNetPay') {
            payload = res.data.jsPayRequest
          } else if (_url === 'Pay.Wxpay') {
            payload = res.data
          } else if (_url === 'LianlianPay.Pay') {
            payload = JSON.parse(res.data.payload)
          }

          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            { ...payload, timeStamp: payload.timeStamp || payload.timestamp },
            function (res) {
              if (res.err_msg === 'get_brand_wcpay_request:ok') {
                Toast.success('支付成功', 1, () =>
                  props.history.replace('/order')
                )
              } else {
                Toast.fail('支付取消', 1, () => props.history.replace('/order'))
              }
            }
          )
        }
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              onBridgeReady,
              false
            )
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          }
        } else {
          onBridgeReady()
        }
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [choices, p_type, props.history, nowTime])

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#F4F4F4',
        padding: '50px 10px 10px 10px',
        position: 'relative',
      }}
    >
      <div
        style={{
          marginBottom: 30,
          marginTop: 30,
          color: '#FF4F64',
          textAlign: 'center',
          fontSize: 22,
        }}
      >
        <span>￥</span>
        <span style={{ fontSize: 28 }}>
          {localStorage.getItem('pay_money')}
        </span>
      </div>
      {_map}
      <button
        style={{
          position: 'fixed',
          height: 45,
          bottom: 10,
          width: '90%',
          left: '5%',
          background: '#FF6D8C',
          borderRadius: 30,
          zIndex: 33,
          color: '#fff',
          fontSize: 18,
        }}
        onClick={handlePay}
      >
        立即支付
      </button>
    </div>
  )
}

export default Pay
