type ExpiryStorageData = {
  value: any
  expiryTime: number
}

/**
 * 存储数据时设置过期时间
 *
 * @param {string} key
 * @param {*} value
 * @param {number} [expiryTimeInSeconds=600]
 */
export function setExpiryStorage(
  key: string,
  value: any,
  expiryTimeInSeconds: number = 600
) {
  const expiryTime = Date.now() + expiryTimeInSeconds * 1000
  const data = { value, expiryTime } as ExpiryStorageData
  localStorage.setItem(key, JSON.stringify(data))
}

/**
 * 读取数据时检查是否过期
 *
 * @param {string} key
 */
export function getExpiryStorage(key: string) {
  try {
    const dataString = localStorage.getItem(key)
    if (!dataString) {
      return null
    }

    const data = JSON.parse(dataString) as ExpiryStorageData
    if (Date.now() > data.expiryTime) {
      localStorage.removeItem(key)
      return null
    }

    return data.value
  } catch (error) {
    console.error(error)
    return null
  }
}
