import { Toast } from 'antd-mobile'
import { useState, useEffect } from 'react'

/**
 * 从视频文件中获取第一帧图像的自定义Hook
 *
 * @param file - 视频文件对象
 * @param showLoading - 是否显示加载提示
 * @returns 一个包含第一帧图像URL和加载状态的数组
 */
export function useVideoFirstFrame(
  file?: File,
  showLoading = true
): [string | null, boolean] {
  const [firstFrame, setFirstFrame] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!file) {
      setFirstFrame(null)
      setLoading(false)
      return
    }

    setLoading(true)

    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = () => {
      video.currentTime = 0
    }

    video.onseeked = () => {
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const ctx = canvas.getContext('2d')
      if (!ctx) {
        setLoading(false)
        return
      }

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const frame = canvas.toDataURL('image/png')
      setFirstFrame(frame)
      setLoading(false)
    }

    const url = URL.createObjectURL(file)
    video.src = url

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [file])

  useEffect(() => {
    if (showLoading && loading) {
      Toast.loading(null, 0)
    } else {
      Toast.hide()
    }
  }, [showLoading, loading])

  return [firstFrame, loading]
}
