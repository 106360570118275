import React from 'react'
import { TabBar } from 'antd-mobile'
import { withRouter } from 'react-router-dom'

class TabBarBottom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 'home',
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location
    switch (pathname) {
      case '/':
        this.setState({
          selectedTab: 'home',
        })
        break
      case '/cart':
        this.setState({
          selectedTab: 'cart',
        })
        break
      case '/member':
        this.setState({
          selectedTab: 'member',
        })
        break
      default:
        break
    }
  }
  render() {
    const shopId = localStorage.getItem('shop_id') || ''
    const branchId = localStorage.getItem('branch_id') || ''

    return (
      <div
        style={{
          position: 'fixed',
          height: 50,
          width: '100%',
          bottom: 0,
          maxWidth: 768,
        }}
      >
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#36a28d"
          barTintColor="white"
        >
          <TabBar.Item
            icon={{ uri: require('../../images/tabbar/list.png').default }}
            selectedIcon={{
              uri: require('../../images/tabbar/list-selected.png').default,
            }}
            title="精选宝贝"
            key="home"
            selected={this.state.selectedTab === 'home'}
            onPress={() => {
              if (this.state.selectedTab === 'home') {
                return
              }
              this.props.history.push(
                `/?shop_id=${shopId}&branch_id=${branchId}`
              )
            }}
            data-seed="logId1"
          ></TabBar.Item>
          <TabBar.Item
            icon={{ uri: require('../../images/tabbar/cart.png').default }}
            selectedIcon={{
              uri: require('../../images/tabbar/cart-selected.png').default,
            }}
            title="购物车"
            key="cart"
            selected={this.state.selectedTab === 'cart'}
            onPress={() => {
              if (this.state.selectedTab === 'cart') {
                return
              }
              this.props.history.push('/cart')
            }}
          ></TabBar.Item>

          <TabBar.Item
            icon={{ uri: require('../../images/tabbar/my.png').default }}
            selectedIcon={{
              uri: require('../../images/tabbar/my-selected.png').default,
            }}
            title="我的"
            key="member"
            selected={this.state.selectedTab === 'member'}
            onPress={() => {
              if (this.state.selectedTab === 'member') {
                return
              }
              this.props.history.push('/member')
            }}
          ></TabBar.Item>
        </TabBar>
      </div>
    )
  }
}

const TabBarWithRouter = withRouter(TabBarBottom)
export default function TabBarWrapper(props) {
  return <TabBarWithRouter {...props} />
}
