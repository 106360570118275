import { Link } from 'react-router-dom'

import { useShopId } from '../../../../hooks'
import { PriceTag } from '..'
import './index.css'

export function SwiperCard(props: any) {
  const { goods_id, goods_image, goods_name, goods_price } = props
  const { shopId, branchId } = useShopId()

  return (
    <Link
      to={`/goods?goods_id=${goods_id}&shop_id=${shopId}&branch_id=${branchId}`}
    >
      <div className="swiper-card">
        <img className="swiper-cover" alt="" src={goods_image} />
        <div className="swiper-name">{goods_name}</div>
        <div className="swiper-price">
          <PriceTag priceStyle={{ color: '#F33258' }} price={goods_price} />
        </div>
      </div>
    </Link>
  )
}
