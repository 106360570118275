import { ChangeEvent, useRef } from 'react'

export type FileUploaderProps = {
  className?: string
  style?: React.CSSProperties
  accept: string
  onSelected: (file: File) => void
  children: React.ReactNode
}

export function FileUploader({
  className,
  style,
  accept,
  onSelected,
  children,
}: FileUploaderProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files || !files.length) return
    onSelected(files[0])
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  return (
    <div className={className} style={style} onClick={handleClick}>
      <input
        type="file"
        ref={inputRef}
        accept={accept}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      {children}
    </div>
  )
}
