import fetch from '../utility/fetcher'

/**
 * 获取 APP 链接
 * @param {number} AppType 1:iOS; 2:Android
 * @param {number} IsStable 0:最新版; 1:稳定版
 */
export function getAppLink(AppType: 1 | 2, IsStable: 0 | 1) {
  return fetch({
    method: 'GET',
    Url: 'App.Link',
    AppType,
    IsStable,
  })
}

export type LiveInfo = {
  status: 0 | 1 | 2 | 3 // 初始状态 / 直播未开始 / 直播中 / 直播结束，可回放
  anchor_avatar: string
  title: string
  intro: string
  wx_app_id: string
}

/**
 * 获取直播间信息
 * @param id - 直播间 ID
 */
export function getLiveInfo(id: string) {
  return fetch<LiveInfo>({
    Url: 'LiveClient.LiveInfo',
    LiveID: id,
  })
}
