import { config } from './config'
import { create } from './http-client'
import { branchTokenHandler, cTokenHandler } from './token'

// 分店接口请求实例
export const branchRequest = create({
  baseURL: config.apiUrl,
  timeout: 30000,
  tokenKey: branchTokenHandler.key,
  autoRelogin: true,
})

// C端接口请求实例
export const cRequest = create({
  baseURL: config.apiUrl,
  timeout: 30000,
  tokenKey: cTokenHandler.key,
})

// C端接口请求实例(微信相关接口，因为服务号IP白名单的缘故需要单独设置域名拎出来)
export const wxRequest = create({
  baseURL: config.jsapiUrl,
  timeout: 30000,
})
