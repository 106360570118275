import { useEffect } from 'react'
import { InputItem, List } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { Button } from '../../components'
import {
  useQuery,
  useTitle,
  useWechatHideAllNonBaseMenuItem,
} from '../../hooks'
import './search.css'

export const initialState = {
  UserName: '',
  UserPhone: '',
  OrderCode: '',
  GoodsName: '',
}

export default function OrderSearch() {
  const history = useHistory()
  const query = useQuery()
  const { data, mutate } = useSWR('/order/search', {
    fetcher: undefined,
    fallbackData: initialState,
  })
  const btnDisabled = Object.values(data || {}).filter(Boolean).length === 0

  useTitle('订单查询')
  useWechatHideAllNonBaseMenuItem()
  useEffect(() => {
    // 返回时才保留状态
    if (history.action !== 'POP') {
      mutate(initialState)
    }
  }, [history.action, mutate])

  function mutateCache(key: keyof typeof initialState, value: string) {
    if (data) {
      mutate({ ...data, [key]: value })
    }
  }

  function onNameChange(val: string) {
    mutateCache('UserName', val)
  }
  function onPhoneChange(val: string) {
    mutateCache('UserPhone', val)
  }
  function onIdChange(val: string) {
    mutateCache('OrderCode', val)
  }
  function onKeywordChange(val: string) {
    mutateCache('GoodsName', val)
  }

  function onSearch() {
    history.push(
      `/order/search-result?use_multi=${query.get('use_multi') === '1' ? 1 : 0}`
    )
  }

  return (
    <div className="order-search">
      <div className="order-search__tip">
        输入收货人姓名、手机号、订单号或商品关键字其中一项，即可查询到相关订单
      </div>

      <List className="order-search__list">
        <InputItem
          placeholder="请输入收货人姓名"
          value={data?.UserName}
          onChange={onNameChange}
        />
        <InputItem
          placeholder="请输入收货人手机号"
          value={data?.UserPhone}
          onChange={onPhoneChange}
        />
        <InputItem
          placeholder="请输入订单号"
          value={data?.OrderCode}
          onChange={onIdChange}
        />
        <InputItem
          placeholder="请输入商品名称或关键字"
          value={data?.GoodsName}
          onChange={onKeywordChange}
        />
      </List>

      <Button
        type="primary"
        size="large"
        isFull
        disabled={btnDisabled}
        onClick={onSearch}
      >
        查 询
      </Button>
    </div>
  )
}
