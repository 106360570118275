import { useCallback, useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, Toast } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

import { BackTop } from '../../components'
import { useIsMiniprogram, useQuery, useShopId } from '../../hooks'
import { request } from '../../utility/request'
import { formatHotAreaHTML } from '../../utility/utils'
import './index.css'

export default function Activity() {
  const history = useHistory()
  const query = useQuery()
  const id = query.get('id') || ''

  const { shopId, branchId } = useShopId()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    title: '',
    desc: '',
    imgUrl: '',
    canShare: 0,
    content: '',
  })
  const html = useMemo(
    () =>
      formatHotAreaHTML({
        html: data.content,
        parentWidth: Math.min(window.innerWidth, 768),
        hrefFormatter: (href) => {
          let goodsid = ''

          if (href.indexOf('goodsid') > -1) {
            goodsid = href.split('=')[1].trim()
          } else if (
            href.indexOf('||') > -1 &&
            href.split('||')[0].trim() === '4'
          ) {
            goodsid = href.split('||')[1].trim()
          }

          return `/goods?goods_id=${goodsid}&shop_id=${shopId}&branch_id=${branchId}`
        },
      }),
    [data.content, shopId, branchId]
  )
  const isMiniprogram = useIsMiniprogram()
  const backHome = useCallback(() => {
    if (isMiniprogram) {
      window.wx.miniProgram?.switchTab({
        url: `/pages/tabBar/home/home?shop_id=${shopId}&branch_id=${branchId}`,
      })
    } else {
      history.replace(`/?shop_id=${shopId}&branch_id=${branchId}`)
    }
  }, [isMiniprogram, history, shopId, branchId])

  // 向小程序发送分享数据
  useEffect(() => {
    if (isMiniprogram && data.title) {
      window.wx.miniProgram?.postMessage({
        data: {
          type: 'onShareAppMessage',
          title: data.title,
        },
      })
    }
  }, [isMiniprogram, data.title])

  useEffect(() => {
    if (!shopId || !id) {
      Toast.fail('链接有误', 2, backHome)
      return
    }

    setLoading(true)

    request({
      url: 'Activity.Detail',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        BranchID: branchId,
        Id: id,
      }),
    })
      .then((res) => {
        if (res.flag === 200) {
          setData(res.data)
        } else if (res.flag === 401) {
          Toast.fail(res.msg || '链接已失效', 2, backHome)
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [shopId, branchId, id, history, backHome])

  useEffect(() => {
    if (loading || !data.title) return

    // 设置页面标题
    const prevTitle = document.title
    document.title = data.title

    // API 请求完成再执行
    window.wx.ready(() => {
      if (Number(data.canShare) === 1) {
        // 设置微信分享信息
        const { title, desc, imgUrl } = data
        const link = window.location.href

        window.wx.updateAppMessageShareData?.({ title, desc, link, imgUrl })
        window.wx.updateTimelineShareData?.({ title, link, imgUrl })
      } else {
        // 隐藏分享按钮
        window.wx.hideOptionMenu()
      }
    })

    return () => {
      document.title = prevTitle
    }
  }, [loading, data])

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    // 禁止网页跳转
    e.preventDefault()
    const element = e.target as HTMLElement

    // 点击 area 标签区域
    if (html && element.tagName === 'AREA') {
      if (isMiniprogram) {
        const search = element.getAttribute('href')?.split('?')[1]
        window.wx.miniProgram?.navigateTo({
          url: `/pages/detail/detail?${search}`,
        })
      } else {
        history.push(element.getAttribute('href') as string)
      }
    }
  }

  return (
    <>
      <div className="activity">
        {loading ? (
          <div className="activity-indicator">
            <ActivityIndicator text="加载中..." />
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            onClick={handleClick}
          ></div>
        )}
      </div>
      <BackTop />
    </>
  )
}
