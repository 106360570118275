import React from 'react'
import { useHistory } from 'react-router-dom'
import { Steps, Toast, ActivityIndicator, InputItem } from 'antd-mobile'
import qs from 'qs'
import { useQuery } from '../../hooks/useQuery'
import { TimeLineToDate } from '../../utility/countdown'
import { request } from '../../utility/request'
import { showConfirm } from '../../utility/modal'

const Step = Steps.Step

export default function AfterSaleD() {
  const history = useHistory()
  const query = useQuery()
  const ReturnIdCrypto = query.get('return_id')
  const apply_type = Number(query.get('apply_type'))

  const [loading, setLoading] = React.useState(true)
  const [bulk, setBulk] = React.useState<any>(null)
  const [log_company, setLC] = React.useState<string>('')
  const [log_code, setLcode] = React.useState<string>('')
  const [forceC, setForceC] = React.useState<number>(3)
  const [nowTime, setNowTime] = React.useState<number>()
  const [showChangeAdd, setShowChangeAdd] = React.useState<boolean>(false)
  const [returnAddress, setReturnAddress] = React.useState<any>({})

  React.useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  React.useEffect(() => {
    request({
      url: 'AfterSale.AfterSaleDetails',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token'),
        ReturnIdCrypto,
      }),
    }).then(function (res: {
      flag: number
      msg: string
      data: any
      server_time: number
    }) {
      if (res.flag === 200) {
        setBulk(res.data)
        setNowTime(res.server_time)
        setShowChangeAdd(false)
      } else {
        res.msg && Toast.fail(res.msg)
      }
      setLoading(false)
    })
  }, [forceC, ReturnIdCrypto])

  React.useEffect(() => {
    if (
      (apply_type === 2 &&
        Number(bulk?.apply_state) === 2 &&
        !bulk?.shipping_code) ||
      showChangeAdd
    ) {
      request({
        url: 'AfterSale.ReturnAddress',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token'),
        }),
      }).then((res) => {
        if (res.flag === 200) {
          setReturnAddress(res.data)
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    }
  }, [apply_type, bulk, showChangeAdd])

  const stepNum = React.useMemo(() => {
    if (bulk) {
      if (apply_type === 2) {
        if (parseInt(bulk.apply_state) === 1) {
          return 1
        } else if (parseInt(bulk.apply_state) === 0) {
          return 0
        } else if (
          bulk.shipping_code &&
          bulk.apply_state === 2 &&
          bulk.delay_state !== 1
        ) {
          return 3
        } else if (bulk.apply_state === 3) {
          return 2
        } else if (bulk.delay_state === 1 && bulk.apply_state === 2) {
          return 4
        } else if (parseInt(bulk.apply_state) === 4) {
          return 6
        } else if (parseInt(bulk.apply_state) === 2) {
          return 2
        }
      } else {
        if (parseInt(bulk.apply_state) === 1) {
          return 1
        } else if (parseInt(bulk.apply_state) === 2) {
          return 2
        } else if (parseInt(bulk.apply_state) === 3) {
          return 1
        } else if (parseInt(bulk.apply_state) >= 4) {
          return 3
        } else if (parseInt(bulk.apply_state) === 0) {
          return 0
        }
      }
    } else {
      return 1
    }
  }, [bulk, apply_type])

  const refundType = React.useMemo(() => {
    if (apply_type === 1) {
      return '退款'
    } else if (apply_type === 2) {
      return '退货'
    }
  }, [apply_type])

  const cancelAfterSale = (): void => {
    showConfirm('', '确定要撤销吗？', () => {
      request({
        url: 'AfterSale.CancelAfterSale',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token'),
          ReturnIdCrypto,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success('已撤销', 1, () => history.goBack())
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })
  }

  const retry = () => {
    history.replace({
      pathname: `/service/${bulk.og_id_crypto}/${bulk.buy_num}/${bulk.goods_price}`,
      search: new URLSearchParams({
        after_sale_hint: bulk.after_sale_hint || '',
      }).toString(),
    })
  }

  const subDelivery = () => {
    if (!log_company || !log_code) {
      Toast.info('请填写物流信息', 1.3)
      return
    }

    setLoading(true)

    request({
      url: 'AfterSale.AfterSaleExpress',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token'),
        ExpressName: log_company,
        ShippingCode: log_code,
        ReturnIdCrypto,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('已提交~', 1, () => setForceC((o) => o + 2))
      } else {
        res.msg && Toast.fail(res.msg)
        setLoading(false)
      }
    })
  }
  return (
    <div style={{ padding: '20px 10px' }}>
      <ActivityIndicator toast text="加载中" animating={loading} />

      {bulk && !loading && (
        <>
          <div>
            <div className="row-line-box">
              <img style={{ width: 100 }} src={bulk.goods_image} alt="" />
              <p style={{ width: '60%' }}>{bulk.goods_name}</p>
            </div>
            <div style={{ color: '#AAABB3' }}>
              <p style={{ marginTop: 8 }}>
                {refundType}原因：{bulk.reason_content}
              </p>

              {apply_type === 2 && (
                <p style={{ marginTop: 8 }}>
                  {refundType}数量：{bulk.goods_num}
                </p>
              )}

              <p style={{ marginTop: 8 }}>
                {refundType}金额：￥{bulk.refund_amount}
              </p>

              <p style={{ marginTop: 8 }}>
                {refundType}说明：{bulk.apply_remark}
              </p>
              {bulk.refunded_freight && bulk.refunded_freight > 0 && (
                <p style={{ marginTop: 8 }}>退运费：{bulk.refunded_freight}</p>
              )}
            </div>
            <div
              className="order-item-bot-left"
              style={{
                borderBottom: 'none',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                borderTop: '1px solid #f4f4f4',
                paddingTop: 12,
              }}
            >
              {apply_type === 2 ? (
                <>
                  {parseInt(bulk.apply_state) === 1 && (
                    <p style={{ fontSize: 16 }}>等待商家处理</p>
                  )}
                  {parseInt(bulk.apply_state) === 0 && (
                    <p style={{ fontSize: 16 }}>
                      售后已取消
                      <br />
                      <small style={{ paddingRight: 8, color: '#AAABB3' }}>
                        {bulk.cancel_reason}
                      </small>
                    </p>
                  )}
                  {/* {
                                            parseInt(bulk.apply_state) === 2 && !bulk.shipping_code && <p style={{ fontSize: 16 }}>买家填写快递单号</p>
                                        } */}
                  {parseInt(bulk.apply_state) === 2 &&
                    parseInt(bulk.delay_state) === 1 && (
                      <p style={{ fontSize: 16 }}>
                        商家已同意您的退货退款申请，请耐心等待商家退款.
                      </p>
                    )}
                  {parseInt(bulk.apply_state) === 2 &&
                    parseInt(bulk.delay_state) !== 1 && (
                      <p style={{ fontSize: 16 }}>
                        商家验货合格后，将在第一时间为您退款，请耐心等待{' '}
                      </p>
                    )}
                  {parseInt(bulk.apply_state) === 4 && (
                    <p style={{ fontSize: 16 }}>退款成功</p>
                  )}
                  {parseInt(bulk.apply_state) === 2 &&
                    !bulk.shipping_code &&
                    parseInt(bulk.delay_state) !== 2 && (
                      <p style={{ fontSize: 16 }}>商家已同意</p>
                    )}
                  {parseInt(bulk.apply_state) === 1 && !bulk.shipping_code && (
                    <button className="del-or-multi" onClick={cancelAfterSale}>
                      撤销申请
                    </button>
                  )}
                  {parseInt(bulk.apply_state) === 2 &&
                    bulk.shipping_code &&
                    bulk.delay_state !== 1 && (
                      <button
                        className="del-or-multi"
                        onClick={() => setShowChangeAdd(true)}
                      >
                        修改物流单号
                      </button>
                    )}

                  {(parseInt(bulk.apply_state) === 0 ||
                    parseInt(bulk.apply_state) === 3) &&
                    parseInt(bulk.refund_reject_count) < 2 && (
                      <button className="del-or-multi" onClick={retry}>
                        重新申请
                      </button>
                    )}
                  {/* {
                                    parseInt(bulk.apply_state) === 2 && !bulk.shipping_code && bulk.express_name=="" &&

                                } */}
                </>
              ) : (
                <>
                  {parseInt(bulk.apply_state) === 1 && (
                    <p style={{ fontSize: 16 }}>等待商家处理</p>
                  )}
                  {parseInt(bulk.apply_state) === 0 && (
                    <p style={{ fontSize: 16 }}>售后已取消</p>
                  )}
                  {parseInt(bulk.apply_state) === 2 && (
                    <p style={{ fontSize: 16 }}>商家已同意</p>
                  )}
                  {parseInt(bulk.apply_state) === 3 && (
                    <p style={{ fontSize: 16 }}>商家已拒绝</p>
                  )}
                  {parseInt(bulk.apply_state) === 4 && (
                    <p style={{ fontSize: 16 }}>您的货款已退回，请注意查收</p>
                  )}
                  {parseInt(bulk.apply_state) === 1 && (
                    <button className="del-or-multi" onClick={cancelAfterSale}>
                      撤销申请
                    </button>
                  )}
                  {(parseInt(bulk.apply_state) === 0 ||
                    parseInt(bulk.apply_state) === 3) &&
                    parseInt(bulk.refund_reject_count) < 2 && (
                      <button className="del-or-multi" onClick={retry}>
                        重新申请
                      </button>
                    )}
                </>
              )}
            </div>

            {apply_type === 2 ? (
              <>
                {parseInt(bulk.apply_state) === 1 && (
                  <div>商家预计48小时内进行处理，请耐心等待</div>
                )}

                {parseInt(bulk.apply_state) === 2 && !bulk.shipping_code && (
                  <div>
                    商家已同意您的退货退款申请，请在
                    {TimeLineToDate(nowTime + bulk.count_down)}
                    前填写物流信息，超时系统将自动取消申请
                  </div>
                )}
                {parseInt(bulk.apply_state) === 2 &&
                  bulk.shipping_code &&
                  parseInt(bulk.delay_state) !== 1 && (
                    <div>商家预计7天内确认收货，请耐心等待</div>
                  )}
                {parseInt(bulk.apply_state) === 3 && (
                  <div>
                    商家拒绝了您的退货退款申请
                    <br />
                    拒绝原因：{bulk.admin_remark}
                  </div>
                )}
                {parseInt(bulk.apply_state) === 4 && (
                  <div>您的货款已退回，请注意查收</div>
                )}
              </>
            ) : (
              <>
                {parseInt(bulk.apply_state) === 1 && (
                  <div>商家预计48小时内进行处理，请耐心等待</div>
                )}
                {parseInt(bulk.apply_state) === 2 && (
                  <div>商家已同意您的退款申请，请耐心等待商家退款</div>
                )}
                {parseInt(bulk.apply_state) === 3 && (
                  <div>
                    商家已拒绝了您的退款申请
                    <br />
                    拒绝原因：{bulk.admin_remark}
                  </div>
                )}
              </>
            )}
          </div>

          {((apply_type === 2 &&
            parseInt(bulk.apply_state) === 2 &&
            !bulk.shipping_code) ||
            showChangeAdd) && (
            <div
              className="fill-logistic order-item-bot-left"
              style={{ flexDirection: 'column', fontSize: 14 }}
            >
              <p>收件人：{returnAddress.companyname}</p>
              <p>电话号码：{returnAddress.phone}</p>
              <p>退货地址：{returnAddress.address}</p>
              <p>邮编：{returnAddress.zipcode}</p>

              <div style={{ padding: 0 }}>
                <InputItem value={log_company} onChange={(e) => setLC(e)}>
                  快递公司:
                </InputItem>
              </div>
              <div style={{ padding: 0 }}>
                <InputItem value={log_code} onChange={(s) => setLcode(s)}>
                  快递单号:
                </InputItem>
              </div>
              <button
                className="del-or-multi"
                style={{ width: 130, alignSelf: 'center' }}
                onClick={subDelivery}
              >
                提交
              </button>
            </div>
          )}

          <section style={{ marginTop: 13, padding: 10 }}>
            {apply_type === 2 ? (
              <Steps current={stepNum}>
                <Step
                  title={'买家申请退货'}
                  description={
                    parseInt(bulk.apply_time) !== 0 &&
                    TimeLineToDate(bulk.apply_time)
                  }
                />
                <Step
                  title="商家处理"
                  description={
                    parseInt(bulk.admin_time) !== 0 &&
                    parseInt(bulk.apply_state) >= 2 &&
                    TimeLineToDate(bulk.admin_time)
                  }
                />
                <Step
                  title="退货"
                  description={
                    parseInt(bulk.send_time) !== 0 &&
                    TimeLineToDate(bulk.send_time)
                  }
                />
                <Step
                  title="商家收货"
                  description={
                    parseInt(bulk.receive_time) !== 0 &&
                    parseInt(bulk.apply_state) >= 4 &&
                    TimeLineToDate(bulk.receive_time)
                  }
                />
                <Step title="退款中" description="" />
                <Step
                  title="退款成功"
                  description={
                    parseInt(bulk.refund_time) !== 0 &&
                    TimeLineToDate(bulk.refund_time)
                  }
                />
              </Steps>
            ) : (
              <Steps current={stepNum}>
                {stepNum === 0 && (
                  <Step
                    title=""
                    description=""
                    style={stepNum === 0 && { display: 'none' }}
                  />
                )}
                <Step
                  title={'买家申请退款'}
                  description={
                    parseInt(bulk.apply_time) !== 0 &&
                    TimeLineToDate(bulk.apply_time)
                  }
                />
                <Step
                  title="等待商家处理"
                  description={
                    parseInt(bulk.admin_time) !== 0 &&
                    TimeLineToDate(bulk.admin_time)
                  }
                />
                <Step title="退款" description="" />
                <Step
                  title="退款成功"
                  description={
                    parseInt(bulk.refund_time) !== 0 &&
                    TimeLineToDate(bulk.refund_time)
                  }
                />
              </Steps>
            )}
          </section>
        </>
      )}
    </div>
  )
}
