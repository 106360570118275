import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { List } from 'antd-mobile'

import { useQuery } from '../hooks/useQuery'
import { showConfirm } from '../utility/modal'
import './user.css'
import { FloatBottom } from '../components'

const Item = List.Item

export default function User() {
  const history = useHistory()
  const query = useQuery()
  const name = decodeURIComponent(query.get('name') || '')
  const avatar = decodeURIComponent(query.get('avatar') || '')

  useEffect(() => {
    document.body.style.setProperty('background', '#f5f5f5', 'important')

    return () => {
      document.body.style.removeProperty('background')
    }
  }, [])

  function exit() {
    showConfirm('确定退出登录？', '', () => {
      localStorage.removeItem('c_token')
      history.go(-1)
    })
  }

  return (
    <>
      <List className="user__list">
        <Item
          extra={<img className="user__avatar" src={avatar} alt="avatar" />}
        >
          头像
        </Item>
        <Item extra={name}>昵称</Item>
      </List>

      <FloatBottom>
        <div className="user__bottom">
          <div>
            <Link className="user__link" to="/help/tos">
              《用户服务协议》
            </Link>
            和
            <Link className="user__link" to="/help/privacy">
              《隐私政策》
            </Link>
          </div>
          <div>
            <span className="user__exit" onClick={exit}>
              退出登录
            </span>
          </div>
        </div>
      </FloatBottom>
    </>
  )
}
