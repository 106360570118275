import { Link } from 'react-router-dom'

import { useShopId } from '../../hooks'
import './index.css'

export type GoodsCardProps = {
  goods_id: string
  goods_image: string
  goods_name: string
  goods_price: string
  activity_type: string
}

export function GoodsCard({
  goods_id,
  goods_image,
  goods_name,
  goods_price,
  activity_type,
}: GoodsCardProps) {
  const { shopId, branchId } = useShopId()

  return (
    <li className="goods-card-container">
      <Link
        to={`/goods?goods_id=${goods_id}&shop_id=${shopId}&branch_id=${branchId}&activity_type=${activity_type}`}
      >
        <div className="goods-card">
          <img
            className="goods-image"
            loading="lazy"
            alt=""
            src={goods_image}
          />
          <div className="goods-info">
            <div className="goods-name">{goods_name}</div>
            <div className="goods-price">￥{goods_price}</div>
          </div>
        </div>
      </Link>
    </li>
  )
}
