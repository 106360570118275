import { useState, useEffect } from 'react'
import { Icon } from 'antd'
import { Button } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'qs'
import TabBarWrapper from '../tabbar/tabbar'
import { MoreOrders } from '../../components'
import { request, reLogin } from '../../utility/request'
import { showConfirm } from '../../utility/modal'
import { config } from '../../utility/config'
import { useQuery } from '../../hooks/useQuery'
import { useToken } from '../../hooks/useToken'
import { useShopId } from '../../hooks'

const mapStateToProps = (state) => {
  return {
    ifLogin: state.ifLogin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    localStorage.removeItem('c_token')
    dispatch({ type: 'LOGOUT' })
  },
})

const My = (props) => {
  const { logout } = props
  const [personInfo, setPersonInfo] = useState({
    member_name: '',
    member_head: '',
    multi_member: 0,
  })
  const [loading, setLoading] = useState(true)
  const [lg, setLG] = useState(0)

  const query = useQuery()
  const from = query.get('from')

  const [key] = useToken()
  const { setShopId } = useShopId()

  useEffect(() => {
    request({
      url: 'Member.MemberInfo',
      method: 'POST',
      body: qs.stringify({
        Key: key,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setShopId(res.data.shop_id)
        setPersonInfo(res.data)

        if (res.data.multi_member === 1) {
          localStorage.setItem('has_multi_account', 1)
        }
      } else if (
        res.flag === 401 && // 未登录
        from === 'lianlian' && // 从连连商家小票跳转而来
        process.env.NODE_ENV !== 'development' // 非开发环境
      ) {
        // 未登录则进行协议跳转，用户可能在另一个协议下的页面登录过
        // 如果进入到下一个协议的页面用户还是未登录，则停留在当前页面
        const { protocol, host, pathname } = window.location
        const isHttps = protocol === 'https:'
        window.location.replace(
          `${isHttps ? 'http:' : 'https:'}//${host}${pathname}`
        )
      } else if (res.flag === 401) {
        logout()
      }

      setLoading(false)
    })
  }, [key, from, logout, setShopId])

  useEffect(() => {
    localStorage.setItem('skip_add', 3)
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  const Inevitable = () => {
    reLogin(function () {
      props.history.replace(
        `/login?shop_id=${localStorage.getItem(
          'shop_id'
        )}&branch_id=${localStorage.getItem('branch_id')}`
      )
    }, 'member')
  }

  const doLoginSoftly = () => {
    showConfirm('', '您还未登录，是否先登录？', () => {
      reLogin(function () {
        props.history.push(
          `/login?shop_id=${localStorage.getItem(
            'shop_id'
          )}&branch_id=${localStorage.getItem('branch_id')}`
        )
      }, 'member')
    })
  }

  // 登出彩蛋
  useEffect(() => {
    if (lg > 9) {
      localStorage.removeItem('c_token')
      alert("yo~,seems like You've found my dirty secret~")
      window.location.reload()
    }
  }, [lg])

  const quit = () => {
    setLG((s) => s + 1)
  }

  return (
    <div style={{ textAlign: 'center', paddingBottom: 50 }}>
      <div onClick={() => quit()} style={{ height: 50 }}></div>
      {props.ifLogin ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={
              personInfo.member_head === '' && !loading
                ? `//${config.imageCdn}/activity/b4a18d33e13be6d08006470b7f0a8d1c.jpeg`
                : personInfo.member_head
            }
            alt=""
            style={{
              width: 80,
              height: 80,
              objectFit: 'contain',
              borderRadius: 10,
            }}
          />
          <p style={{ marginTop: 10, fontSize: 18 }}>
            {personInfo.member_name}
          </p>
        </div>
      ) : (
        <div style={{ padding: 30, width: 200, margin: '0 auto' }}>
          <Button
            className="inevitable-login-btn"
            onClick={Inevitable}
            type="primary"
            style={{ borderColor: '#9CCEC3', background: '#9CCEC3' }}
          >
            登录
          </Button>
        </div>
      )}

      {props.ifLogin ? (
        <>
          <Link to="/order">
            <div className="row-line-box" style={{ marginTop: 20 }}>
              <h3>我的订单</h3>
              <Icon type="right" />
            </div>
          </Link>

          <div
            className="row-line-box order-icon"
            style={{ padding: '20px 30px 10px 30px', borderBottom: 'none' }}
          >
            <Link to="/order?order_state=1">
              {personInfo &&
              personInfo.order_new_count &&
              parseInt(personInfo.order_new_count) > 0 ? (
                <b className="num-tips">
                  {personInfo ? personInfo.order_new_count : 0}
                </b>
              ) : (
                ''
              )}
              <Icon type="wallet" style={{ fontSize: 28 }} />
              <p>待付款</p>
            </Link>
            <Link to="/order?order_state=2">
              {personInfo &&
              personInfo.order_send_count &&
              parseInt(personInfo.order_send_count) > 0 ? (
                <b className="num-tips">
                  {personInfo ? personInfo.order_send_count : 0}
                </b>
              ) : (
                ''
              )}
              <Icon type="export" style={{ fontSize: 28 }} />
              <p>待发货</p>
            </Link>
            <Link to="/order?order_state=3">
              {personInfo &&
              personInfo.order_receipt_count &&
              parseInt(personInfo.order_receipt_count) > 0 ? (
                <b className="num-tips">
                  {personInfo ? personInfo.order_receipt_count : 0}
                </b>
              ) : (
                ''
              )}
              <Icon type="inbox" style={{ fontSize: 30 }} />
              <p>已发货</p>
            </Link>
            <Link to="/commentlist">
              {personInfo &&
              personInfo.wait_evaluate_count &&
              parseInt(personInfo.wait_evaluate_count) > 0 ? (
                <b className="num-tips">
                  {personInfo ? personInfo.wait_evaluate_count : 0}
                </b>
              ) : (
                ''
              )}
              <Icon type="message" style={{ fontSize: 28 }} />
              <p>待评价</p>
            </Link>
          </div>

          <a
            className="row-line-box"
            style={{ marginTop: 30 }}
            href="/address_list"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                src={require('../../images/my/ic_my_address.png').default}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              <p>我的地址</p>
            </div>
            <Icon type="right" />
          </a>
          <Link className="row-line-box" to="/aftersalelist">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                src={require('../../images/my/ic_shop_Aftermarket.png').default}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              <p>售后服务</p>
            </div>

            <Icon type="right" />
          </Link>
          <Link className="row-line-box" to="/commentlist">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="message" style={{ marginRight: 10, fontSize: 20 }} />
              <p>我的评价</p>
            </div>
            <Icon type="right" />
          </Link>
          <Link
            className="row-line-box"
            to={`/user?name=${encodeURIComponent(
              personInfo.member_name
            )}&avatar=${encodeURIComponent(personInfo.member_head)}`}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="user" style={{ marginRight: 10, fontSize: 20 }} />
              <p>个人资料</p>
            </div>
            <Icon type="right" />
          </Link>
        </>
      ) : (
        <div onClick={doLoginSoftly}>
          <div>
            <div className="row-line-box" style={{ marginTop: 20 }}>
              <h3>我的订单</h3>
              <Icon type="right" />
            </div>
          </div>

          <div
            className="row-line-box order-icon"
            style={{ padding: '20px 30px 10px 30px', borderBottom: 'none' }}
          >
            <div>
              <Icon type="wallet" style={{ fontSize: 28 }} />
              <p>待付款</p>
            </div>
            <div>
              <Icon type="export" style={{ fontSize: 28 }} />
              <p>待发货</p>
            </div>
            <div>
              <Icon type="inbox" style={{ fontSize: 30 }} />
              <p>已发货</p>
            </div>
            <div>
              <Icon type="message" style={{ fontSize: 28 }} />
              <p>待评价</p>
            </div>
          </div>

          <div className="row-line-box" style={{ marginTop: 30 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                src={require('../../images/my/ic_my_address.png').default}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              <p>我的地址</p>
            </div>
            <Icon type="right" />
          </div>
          <div className="row-line-box">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt=""
                src={require('../../images/my/ic_shop_Aftermarket.png').default}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              <p>售后服务</p>
            </div>
            <Icon type="right" />
          </div>
          <div className="row-line-box">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="message" style={{ marginRight: 10, fontSize: 20 }} />
              <p>我的评价</p>
            </div>
            <Icon type="right" />
          </div>
          <div className="row-line-box">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon type="user" style={{ marginRight: 10, fontSize: 20 }} />
              <p>个人资料</p>
            </div>
            <Icon type="right" />
          </div>
        </div>
      )}

      <div style={{ height: personInfo.multi_member === 1 ? 66 : 16 }}></div>

      {personInfo.multi_member === 1 && (
        <div
          style={{
            position: 'fixed',
            bottom: 50,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
          }}
        >
          <MoreOrders />
        </div>
      )}

      <TabBarWrapper />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(My)
