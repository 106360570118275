import { Toast } from 'antd-mobile'
import qs from 'qs'
import store from '../redux/store'
import { isWeChat, isWxWork } from './utils'
import { commonUrl, version } from './config'

/**
 * 重新登录
 * @param {Function} [callback] 回调函数
 * @param {String} [pathname] 路由地址
 */
function reLogin(callback, pathname = '') {
  const appid = localStorage.getItem('appId')

  localStorage.removeItem('c_token')
  store.dispatch({ type: 'LOGOUT' })

  if (isWeChat && !isWxWork) {
    const { protocol, host } = window.location
    const newPath = pathname.startsWith('/') ? pathname : `/${pathname}`
    const redirect_uri = encodeURIComponent(`${protocol}//${host}${newPath}`)

    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo`
  } else if (callback) {
    callback()
  }
}

// 解析json
function parseJSON(response) {
  return response.json()
}

function statusFun(res) {
  if (res.flag === 999) {
    Toast.info(res.msg, 1)
    return {
      ...res,
      msg: '',
    }
  } else {
    return res
  }
}

// 检查请求状态
function checkStatus(response) {
  if (response.status >= 200 && response.status < 500) {
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

// 封装请求
function request(options) {
  const { url, body = '' } = options
  const params = qs.parse(body)
  const storageShopId = localStorage.getItem('shop_id')
  const storageBranchId = localStorage.getItem('branch_id')

  // 所有请求都添加 ShopID 传参
  // 没有传入 ShopID 并且本地存有 ShopID，则会自动拼接
  if (typeof params.ShopID === 'undefined' && storageShopId) {
    params.ShopID = storageShopId
  }

  // 添加 BranchID 传参
  if (storageBranchId) {
    params.BranchID = storageBranchId
  }

  // 版本号
  params.Version = version

  options.body = qs.stringify(params)
  options.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }

  delete options.url

  return fetch(commonUrl + url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(statusFun)
    .catch((err) => ({ err }))
}

export { request, reLogin }
