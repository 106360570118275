import * as React from 'react'
import { Stepper as AntdStepper, Toast } from 'antd-mobile'
import { StepProps } from 'antd-mobile/lib/stepper'

export interface StepperProps extends Omit<StepProps, 'value'> {
  style?: React.CSSProperties
  min: number
  max: number
  defaultValue: number
  useMax?: boolean // 是否使用 max 和 defaultValue 中的最大值
}

export function Stepper(props: StepperProps) {
  const { min, max, defaultValue = 1, onChange, useMax, ...rest } = props
  const [value, setValue] = React.useState(+defaultValue)
  const timer = React.useRef<any>()

  // 值变化时防抖
  function handleChange(val: any) {
    clearTimeout(timer.current)
    setValue(val)

    // 没有变化
    if (val === +value || val === '') {
      return
    }

    // 输入框为空失去焦点时，值为 undefined，回填默认值
    if (typeof val === 'undefined') {
      setValue(+defaultValue)
      return
    }

    // 是数字但是大于库存
    if (typeof val === 'number' && val > max) {
      timer.current = setTimeout(() => {
        Toast.info(
          '库存不足',
          1,
          () => {
            setValue(max)
            onChange && onChange(max)
          },
          true
        )
      }, 300)
      return
    }

    timer.current = setTimeout(() => {
      // 处理小数
      const newVal = Math.floor(val)

      // 如果不是数字
      if (isNaN(newVal)) return

      // 如果是整数
      setValue(newVal)
      onChange && onChange(newVal)
    }, 300)
  }

  return (
    <AntdStepper
      {...rest}
      showNumber
      min={min}
      max={useMax ? Math.max(max, +defaultValue) : max}
      defaultValue={+defaultValue}
      value={value}
      onChange={handleChange}
    />
  )
}
