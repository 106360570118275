import { useEffect } from 'react'

import { showForceLoginModal } from '../utility/utils'

export type ErrorResponse = {
  flag: number
  msg: string
}

/**
 * 处理错误返回
 * @param error 接口返回的数据
 */
export function useErrorResponse(error?: ErrorResponse) {
  useEffect(() => {
    // 强制重新登录
    if (error?.flag === 401) {
      showForceLoginModal(error.msg)
    }
  }, [error])
}
