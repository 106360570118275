import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Toast } from 'antd-mobile'
import { Link } from 'react-router-dom'
import './list.css'
import NavTopBar from '../public/navBar'
import QueueAnim from 'rc-queue-anim'
import { request } from '../../utility/request'
import handlePages from '../../utility/handlePages'
import { _t } from '../../utility/track'
import qs from 'qs'

export default function SearchIndex(props) {
  const [noMore, setNoMore] = useState(false)
  const [PageIndex, setPageIndex] = useState(1)
  const [typeName, setTypeName] = useState('')
  const [goodsList, setGoodsList] = useState([])
  const key = localStorage.getItem('c_token')
  const ClassID = parseInt(props.location.search.split('=')[1]) || 0
  const [nowTime, setNowTime] = useState(0)

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })

    _t('pageview')
  }, [])

  useEffect(() => {
    // setFinished(false);
    // if(!finished){
    //     return
    // }
    request({
      url: 'Selection.ClassGoods',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        PageIndex,
        ClassID,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data) {
          setGoodsList((prev) => prev.concat(res.data.goods_list))
        } else {
          setNoMore(true)
        }
        setTypeName(res.data.type_name.type_name)
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }, [key, PageIndex, ClassID])

  const handleScorll = useCallback(() => {
    let rest = handlePages()
    if (rest && !noMore) {
      if (Date.now() - nowTime > 1000) {
        setNowTime(Date.now())
        setPageIndex((n) => n + 1)
      }
    }
  }, [noMore, nowTime])
  useEffect(() => {
    window.addEventListener('scroll', handleScorll)
    window.addEventListener('touchmove', handleScorll)
    return () => {
      window.removeEventListener('scroll', handleScorll)
      window.removeEventListener('touchmove', handleScorll)
    }
  }, [noMore, handleScorll])

  return (
    <div style={{ textAlign: 'center', paddingTop: 40 }}>
      <NavTopBar title={typeName} backFn={false} />
      <QueueAnim>
        <Grid
          data={goodsList}
          key={68}
          columnNum={2}
          square={false}
          hasLine={false}
          renderItem={(dataItem) => (
            <div style={{ padding: '0 6px' }}>
              <Link
                to={'/goods-detail?gid=' + dataItem.goods_basicid}
                style={{ textAlign: 'left' }}
              >
                <div
                  style={{
                    height: 180,
                    overflow: 'hidden',
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                  }}
                >
                  <img
                    src={dataItem.goods_image}
                    style={{ width: '100%' }}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    color: '#888',
                    fontSize: '14px',
                    marginBottom: 0,
                    minHeight: 40,
                  }}
                >
                  {dataItem.goods_name}
                </p>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p style={{ color: '#C83B4C' }}>
                    {dataItem.goods_wholesale_price1}
                  </p>
                  <span style={{ color: '#9C9C9C', fontSize: 12 }}>
                    {dataItem.stock_begin_num}件起批
                  </span>
                </div>
              </Link>
            </div>
          )}
        />
        {goodsList.length === 0 && (
          <p style={{ marginTop: 60 }}>暂无该分类商品哦~</p>
        )}
      </QueueAnim>
    </div>
  )
}
