import { Icon } from 'antd'
import { Button } from 'antd-mobile'
import clsx from 'clsx'

import styles from './index.module.css'

type ErrorBlockBaseProps = {
  className?: string
  style?: React.CSSProperties
  fullPage?: boolean
}

export type ErrorBlockProps = ErrorBlockBaseProps &
  (
    | {
        title?: string
        desc?: string
        showButton?: boolean
        buttonText?: string
        onButtonClick?: () => void
      }
    | {
        children: React.ReactNode
      }
  )

/**
 * 错误边界组件
 * @example
 * ```tsx
 * import ErrorBlock from './components';
 *
 * function App() {
 *   return (
 *     <ErrorBlock>
 *       <div>Oops! An error occurred.</div>
 *     </ErrorBlock>
 *   );
 * }
 *
 * function App() {
 *   return (
 *     <ErrorBlock
 *       title="Error"
 *       desc="Something went wrong"
 *       showButton={true}
 *       buttonText="Retry"
 *       onButtonClick={() => {
 *         // 处理重试逻辑
 *       }}
 *     />
 *   );
 * }
 * ```
 */
export function ErrorBlock(props: ErrorBlockProps) {
  if ('children' in props) {
    return (
      <div
        className={clsx(
          styles.container,
          props.fullPage && styles['container--fullpage'],
          props.className
        )}
        style={props.style}
      >
        <Icon
          type="exclamation-circle"
          theme="filled"
          style={{ fontSize: 72, color: '#f8b500' }}
        />
        {props.children}
      </div>
    )
  }

  return (
    <div
      className={clsx(
        styles.container,
        props.fullPage && styles['container--fullpage'],
        props.className
      )}
      style={props.style}
    >
      <Icon
        type="exclamation-circle"
        theme="filled"
        style={{ fontSize: 72, color: '#f8b500' }}
      />
      {props.title ? <div className={styles.title}>{props.title}</div> : null}
      {props.desc ? <div className={styles.desc}>{props.desc}</div> : null}
      {props.showButton && (
        <Button className={styles.button} onClick={props.onButtonClick}>
          {props.buttonText || '我知道了'}
        </Button>
      )}
    </div>
  )
}
