import { useState } from 'react'
import { useRequest } from './useRequest'
import { useTimeout } from './useTimeout'

const MAX_VIEW_TIMES = Infinity // 每天最多访问店铺数量
const defaultData = JSON.stringify({
  date: new Date().toLocaleDateString('en-US'),
  shopIds: [],
})

export type ShopViewProps = {
  shopId?: string
  key?: string
}

/**
 * 店铺 PV
 * @description 没有 shopId 和 key 不请求，每天最多3个店铺，每个店铺每天只请求一次
 */
export function useShopView({ shopId, key }: ShopViewProps) {
  const ShopID = shopId || localStorage.getItem('shop_id') || ''
  const Key = key || localStorage.getItem('c_token') || ''

  const [shouldFetch, setShouldFetch] = useState(false)

  useTimeout(() => {
    // 如果没有 shopId 和 key 则不请求
    if (!ShopID || !Key) return

    const shopView = JSON.parse(localStorage.getItem('shopView') || defaultData)
    const now = new Date().toLocaleDateString('en-US')

    // 处理今日访问店铺
    if (now === shopView.date) {
      const isVisited = shopView.shopIds.indexOf(ShopID) > -1
      const hasReachLimit = shopView.shopIds.length >= MAX_VIEW_TIMES

      // 今日已访问或者超限则不请求
      if (isVisited || hasReachLimit) return

      shopView.shopIds.push(ShopID)
    } else {
      // 处理新的一天
      shopView.shopIds = [ShopID]
      shopView.date = now
    }

    localStorage.setItem('shopView', JSON.stringify(shopView))
    setShouldFetch(true)
  }, 5000)

  useRequest(shouldFetch ? { Url: 'Selection.Browse', ShopID, Key } : null)
}
