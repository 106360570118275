import { useEffect, useState } from 'react'
import { ActivityIndicator } from 'antd-mobile'

import { useQuery } from '../../hooks'
import type { LianlianPayInfo } from '../../api/lianlian'
import { getLianlianPayInfo } from '../../api/lianlian'
import successUrl from '../../images/success.png'
import './receipt.css'

type PostMessageData = {
  action: 'onIframeReady' | 'jumpOut'
  displayStyle?: 'SHOW_CUSTOM_PAGE' | 'SHOW_OFFICIAL_PAGE'
  jumpOutUrl?: string
  height?: number
}

function postParentMessage(data: PostMessageData) {
  const postData = JSON.stringify(data)

  window.parent.postMessage(postData, 'https://payapp.weixin.qq.com')
}

// 高度换算
function getHeight() {
  const customPageHeight = document.body.scrollHeight
  const height =
    customPageHeight *
    (640 / Math.round(document.documentElement.getBoundingClientRect().width))

  if (height <= 600) {
    return 600
  } else if (height <= 960) {
    return height
  } else {
    return 960
  }
}

/**
 * 连连支付完成后跳转页面的内嵌 iframe
 */
function Receipt() {
  const query = useQuery()
  const subChnlNo = query.get('out_trade_no')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<LianlianPayInfo>()

  useEffect(() => {
    const timer = setTimeout(() => {
      getPayInfo()
    }, 2000)

    function getPayInfo() {
      if (subChnlNo) {
        getLianlianPayInfo(subChnlNo)
          .then((res) => setData(res))
          .catch((err) => console.error(err.msg))
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    return () => clearTimeout(timer)
  }, [subChnlNo])

  // 显示商家小票
  useEffect(() => {
    postParentMessage({
      action: 'onIframeReady',
      displayStyle: 'SHOW_CUSTOM_PAGE',
      height: getHeight(),
    })
  }, [])

  // 点击跳转到个人中心
  function navigateBack() {
    postParentMessage({
      action: 'jumpOut',
      jumpOutUrl: `https://${window.location.host}/member?from=lianlian`,
    })
  }

  if (isLoading) {
    return <ActivityIndicator toast />
  }

  return (
    <div className="lianlian-receipt">
      <img
        className="lianlian-receipt__success"
        src={successUrl}
        alt="支付成功"
      />
      <div className="lianlian-receipt__text">支付成功</div>
      {data && (
        <div className="lianlian-receipt__rows">
          <div className="lianlian-receipt__row">
            <span className="lianlian-receipt__label">订单金额</span>
            <span className="lianlian-receipt__value">¥{data.order_total}</span>
          </div>
          <div className="lianlian-receipt__row">
            <span className="lianlian-receipt__label">商家单号</span>
            <span className="lianlian-receipt__value">{data.pay_code}</span>
          </div>
          <div className="lianlian-receipt__row">
            <span className="lianlian-receipt__label">连连单号</span>
            <span className="lianlian-receipt__value">
              {data.lian_trade_no}
            </span>
          </div>
          <div className="lianlian-receipt__row">
            <span className="lianlian-receipt__label">微信单号</span>
            <span className="lianlian-receipt__value">
              {data.wxpay_trade_no}
            </span>
          </div>
        </div>
      )}
      {/* PC充值的小票链接禁止跳转 */}
      {data && data.order_type !== 'pc_recharge' && (
        <div className="lianlian-receipt__button" onClick={navigateBack}>
          返回个人中心
        </div>
      )}
    </div>
  )
}

export default Receipt
