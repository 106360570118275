import React, { useState, useEffect, SFC } from 'react'
import { request, reLogin } from '../../utility/request'
import qs from 'qs'

interface CLL {
  location: any
  history: any
}
declare global {
  interface Window {
    wx: any
  }
}
const CommentsDetail: SFC<CLL> = (props) => {
  const [data, setData] = useState<null | any>(null)
  const evaluate_id = parseInt(props.location.search.split('=')[1]) || 0
  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])
  useEffect(() => {
    request({
      url: 'Evaluate.EvaluateInfo',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || null,
        EvaluateID: evaluate_id,
      }),
    }).then(function (res) {
      if (res.flag === 200 && res.data) {
        setData(res.data)
      } else if (res.flag === 401 || res.flag === 420) {
        reLogin(() => {
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      }
    })
  }, [props.history, evaluate_id])
  return (
    <div
      style={{
        background: '#f2f2f2',
        minHeight: '100vh',
        padding: '20px 12px',
      }}
    >
      <div className="row-line-box" style={{ borderColor: '#DDDDDD' }}>
        <img
          src={data ? data.goods_image : ''}
          alt=""
          style={{ width: 90, height: 90, objectFit: 'cover' }}
        />
        <p style={{ width: '64%', alignSelf: 'flex-start' }}>
          {data ? data.goods_name : ''}
        </p>
      </div>

      <p
        style={{
          background: 'transparent',
          width: '100%',
          marginBottom: 20,
          marginTop: 10,
        }}
      >
        {data ? data.eva_content : ''}
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {data &&
          data.eva_image &&
          data.eva_image.map((item: string, idx: number) => (
            <img
              src={item}
              alt=""
              key={idx}
              style={{
                width: 100,
                height: 100,
                objectFit: 'contain',
                marginLeft: 6,
              }}
            />
          ))}
      </div>
    </div>
  )
}
export default CommentsDetail
