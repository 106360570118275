const token = localStorage.getItem('c_token')

const defaultState = {
  ifLogin: token ? true : false,
  wechatAddress: null,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, ifLogin: true }
    case 'LOGOUT':
      return { ...state, ifLogin: false }
    case 'SET_WECHAT_ADDRESS':
      return { ...state, wechatAddress: action.payload }
    default:
      return state
  }
}

export default reducer
