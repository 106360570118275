import { useTitle } from '../hooks/useTitle'
import { downloadApp } from '../utility/utils'
import { config } from '../utility/config'
import './register/register.css'

function Download() {
  useTitle(`下载${config.appName}APP`)

  return (
    <div className="reg-done-page">
      <button className="down-app" onClick={() => downloadApp(0)}>
        下载{config.appName}APP
      </button>
    </div>
  )
}

export default Download
