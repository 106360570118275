import { branchRequest, wxRequest } from '../utility/http-instance'
import { branchTokenHandler } from '../utility/token'

const { getToken } = branchTokenHandler

/**
 * 小店店主登录
 * @param code 微信code
 */
export function getBranchToken(code: string) {
  return wxRequest.post<{ Key: string }>('Wechat.BranchCallback', {
    Code: code,
  })
}

export type BranchInfo = {
  shop_id: string
  branch_id: string
  shop_name: string
  shop_logo: string
  shop_desc: string
  branch_shop_name: string
  branch_shop_logo: string
  branch_shop_desc: string
  wx_qrcode: string
  today_sale_amount: string
  profit_money: string
  wait_profit_money: string
  unhandle_refund_num: string
}

/**
 * 获取小店店铺信息
 */
export function getBranchInfo() {
  return branchRequest.post<BranchInfo>('BranchShop.ShopInfo', {
    Key: getToken(),
  })
}

/**
 * 更新小店店铺信息
 * @param data 更新的数据
 * @param data.shopLogo 店铺logo
 * @param data.shopName 店铺名称
 * @param data.shopDesc 店铺描述
 */
export function updateBranchInfo(data: {
  shopLogo?: string
  shopName?: string
  shopDesc?: string
}) {
  return branchRequest.post('BranchShop.UpdateBranchInfo', {
    Key: getToken(),
    ...data,
  })
}

/**
 * 获取小店店铺小程序二维码
 */
export function getBranchWxQrcodeHome() {
  return branchRequest.post<string>('BranchShop.GetWxqrcodeHome', {
    Key: getToken(),
  })
}

/**
 * 获取小店店铺短链
 */
export function getBranchShortUrlHome() {
  return branchRequest.post<{ url: string }>('BranchShop.GetShortUrl', {
    Key: getToken(),
  })
}

/**
 * 获取小店店铺分享图片
 */
export function getBranchShareImages() {
  return branchRequest.post<{
    images_list: string[]
    shop_share_service_icon: string
  }>('BranchShop.GetShareImage', {
    Key: getToken(),
  })
}

/**
 * 更新二维码
 * @param key 二维码key
 */
export function updateBranchQrcode(key: string) {
  return branchRequest.post('BranchShop.EditQrCode', {
    Key: getToken(),
    qrCodeUrl: key,
  })
}

export type BranchSalesItem = {
  order_id_crypto: string
  order_code: string
  order_total: string
  add_time: string
  refund_amount: string
}

/**
 * 获取小店总销售额
 */
export function getBranchSaleAmountTotal() {
  return branchRequest.post<{ sale_amount_total: string }>(
    'BranchShop.GetSaleAmountTotal',
    { Key: getToken() }
  )
}

export type BranchSalesIncome = {
  order_id_crypto: string
  order_code: string
  title: string
  order_total: string
  add_time: string
  profit_money: string
}

export type BranchOrderCounts = {
  all: string
  unpay: string
  wait_send: string
  unreceipted: string
  complete: string
  close: string
}

/**
 * 获取小店订单数量
 */
export function getBranchOrderCounts() {
  return branchRequest.post<BranchOrderCounts>('BranchShop.OrderCounts', {
    Key: getToken(),
  })
}

export type BranchOrderDetail = {
  order_id_crypto: string
  order_code: string
  order_state: 0 | 10 | 20 | 30 | 40
  member_name: string
  full_name: string
  user_phone: string
  user_area: string
  user_address: string
  // FIXME: 使用具体类型
  order_goods: any[]
  goods_total: string
  order_remark: string
  order_total: string
  freight: string
  meng_add_time: string
  time_extend: {
    pay_time: string
    pay_time_show: 0 | 1
    cancel_time: string
    cancel_time_show: 0 | 1
  }
  allow_confirm: 0 | 1
  show_express: 0 | 1
}

/**
 * 获取小店订单详情
 * @param id 订单id
 */
export function getBranchOrderDetail(id: string) {
  return branchRequest.post<BranchOrderDetail>('BranchShop.OrderDetail', {
    Key: getToken(),
    orderIdCrypto: id,
  })
}

export type QiniuToken = {
  token: string
  key: string
}

/**
 * 获取七牛token
 * @param prefix 七牛前缀
 */
export function getBranchQiniuToken(prefix: string = '') {
  return branchRequest.post<{ token: string; key: string }>(
    'BranchShop.GetQiNiuToken',
    {
      Key: getToken(),
      prefix,
    }
  )
}

/**
 * 获取待处理售后数量
 */
export function getBranchAftersaleUnhandleCount() {
  return branchRequest.post<{ count: number }>(
    'BranchShop.RefundUnhandleCount',
    {
      Key: getToken(),
    }
  )
}

export type AftersaleDetail = {
  shop_id: string
  member_id: string
  goods_id: string
  goods_name: string
  goods_price: string
  goods_num: string
  goods_image: string
  apply_type: string
  receipt_type: string
  delay_state: string
  apply_state: string
  apply_time: string
  admin_time: string
  reason_content: string
  apply_remark: string
  admin_remark: string
  express_id: string
  shipping_code: string
  send_time: string
  refund_time: string
  receive_remark: null | string
  refund_amount: string
  admin_amount: string
  express_name: string
  achi_refund_amount: string
  is_cancel_order: string
  video_url: null | string
  receive_time: string
  urgency: string
  over_time: null | string
  coupon_id: string
  wait_shop: string
  shop_remark: null | string
  shop_time: string
  apply_msg_time: string
  send_msg_time: string
  cancel_time: string
  is_auto_agree: string
  is_auto_refund: string
  is_auto_receive: string
  cancel_remark: string
  refunded_freight: string
  refund_type: string
  offline_refund_cert: null | string
  origin_refund_amount: string
  add_time: string
  update_time: string
  count_down: number
  coupon_text: string
  jump_type: number
  refund_log: {
    refundlog_id: string
    refund_id: string
    log_title: string
    log_content: string
    log_time: string
    admin_id: string
  }[]
  refund_count: string
  full_name: string
  user_phone: string
  user_area: string
  user_address: string
  order_code: string
  fill_in: number
  images_num: number
  has_video: number
  has_image: number
  images_list: null
  og_id_crypto: string
  return_id_crypto: string
  order_id_crypto: string
  refund_instructions: string
}

/**
 * 获取售后详情
 * @param id 售后id
 */
export function getBranchAftersaleDetail(id: string) {
  return branchRequest.post<AftersaleDetail>('BranchShop.RefundDetail', {
    Key: getToken(),
    returnIdCrypto: id,
  })
}

/**
 * 处理售后
 */
export function handleBranchAftersaleRefund(
  id: string,
  state: number,
  remark = ''
) {
  return branchRequest.post('BranchShop.RefundHandle', {
    Key: getToken(),
    returnIdCrypto: id,
    state,
    remark,
  })
}

/**
 * 取消申请售后
 * @param id 售后id
 */
export function cancelBranchAfterSale(id: string) {
  return branchRequest.post('BranchShop.CancelAfterSale', {
    Key: getToken(),
    returnIdCrypto: id,
  })
}

export type BranchAfterSaleMoneyInfo = {
  max_num: string
  max_money: string
  refund_num: string
  refund_money: string
}

/**
 * 获取售后金额
 * @param id 售后id
 * @param num 数量
 */
export function getBranchAfterSaleMoneyInfo(id: string, num: number = 1) {
  return branchRequest.post<BranchAfterSaleMoneyInfo>('BranchShop.MoneyInfo', {
    Key: getToken(),
    ogIdCrypto: id,
    num,
  })
}

/**
 * 获取售后原因列表
 */
export function getBranchAfterSaleReasonList() {
  return branchRequest.post<Array<{ label: string; value: string }>>(
    'AfterSale.ReasonList',
    {
      Key: getToken(),
    }
  )
}

export type BranchAfterSaleParams = {
  ogIdCrypto: string
  applyType: '1' | '2'
  delayType?: string
  reason: string
  num?: number
  money: string
  remark?: string
  imageUrl?: string[]
  videoUrl?: string
}

/**
 * 分店店主给用户申请售后
 */
export function addBranchAfterSale(params: BranchAfterSaleParams) {
  return branchRequest.post('BranchShop.AddAfterSale', {
    Key: getToken(),
    ...params,
  })
}

export type BranchOrderPackage = {
  title: string
  express_name: string
  shipping_code: string
  goods: {
    goods_image: string
    send_num: string
    goods_name: string
  }[]
  num: number
}

/**
 * 获取包裹信息
 * @param orderIdCrypto 订单id
 */
export function getBranchOrderPackages(orderIdCrypto: string) {
  return branchRequest.post<BranchOrderPackage[]>('BranchShop.Express', {
    Key: getToken(),
    orderIdCrypto,
  })
}

export type BranchOrderLogistics = {
  data: {
    time: string
    ftime: string
    context: string
  }[]
}

/**
 * 获取物流信息
 * @param shippingCode 物流单号
 */
export function getBranchOrderLogistics(shippingCode: string) {
  return branchRequest.post<BranchOrderLogistics>('BranchShop.ExpressQuery', {
    Key: getToken(),
    shippingCode,
  })
}

/**
 * 子订单确认收货
 * @param ogIdCrypto 子订单id
 */
export function confirmBranchSubOrder(ogIdCrypto: string) {
  return branchRequest.post('BranchShop.ConfirmSubOrder', {
    Key: getToken(),
    ogIdCrypto,
  })
}

/**
 * 大订单确认收货
 * @param orderIdCrypto 订单id
 */
export function confirmBranchOrder(orderIdCrypto: string) {
  return branchRequest.post('BranchShop.ConfirmOrder', {
    Key: getToken(),
    orderIdCrypto,
  })
}

export type BranchOrderGoodsInfo = {
  goods_image: string
  goods_name: string
  goods_spec: string
  goods_num: string
  member_name: string
  full_name: string
}

/**
 * 获取订单商品信息
 * @param ogIdCrypto 子订单id
 */
export function getBranchOrderGoodsInfo(ogIdCrypto: string) {
  return branchRequest.post<BranchOrderGoodsInfo>('BranchShop.GoodsInfo', {
    Key: getToken(),
    ogIdCrypto,
  })
}

/**
 * 获取验证码
 * @param mobile 手机号
 * @param ticket 验证码ticket
 * @param randStr 验证码随机字符串
 */
export function getBranchVerifyCode(
  mobile: string,
  ticket: string,
  randStr: string
) {
  return branchRequest.post('BranchShop.Verifycode', {
    mobile,
    ticket,
    randStr,
  })
}

/**
 * 使用手机号+验证码登录
 * @param mobile 手机号
 * @param verifyCode 验证码
 */
export function loginWithVerifyCode(mobile: string, verifyCode: string) {
  return branchRequest.post<{ Key: string }>('BranchShop.MobileLogin', {
    mobile,
    verify_code: verifyCode,
  })
}
