import React from 'react'
import './index.css'

export type PriceTagProps = {
  type?: 'red' | 'orange'
  tag?: string
  tagStyle?: Object
  price: string | number
  priceStyle?: Object
}

export function PriceTag({
  type,
  tag,
  tagStyle,
  price,
  priceStyle,
}: PriceTagProps) {
  return (
    <span className={`price--${type}`}>
      {type ? (
        <span className="price-tag" style={tagStyle}>
          {tag}
        </span>
      ) : null}
      <span className="price-text" style={priceStyle}>
        ¥{price}
      </span>
    </span>
  )
}
