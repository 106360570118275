import clsx from 'clsx'
import './index.css'

export interface ButtonProps {
  className?: string
  type?: 'primary' | 'plain'
  size?: 'small' | 'large'
  disabled?: boolean
  isFull?: boolean
  children: React.ReactNode
  [key: string]: any
}

export function Button({
  className,
  type = 'primary',
  size = 'small',
  isFull = false,
  children,
  ...props
}: ButtonProps) {
  const { disabled, ...rest } = props

  return (
    <button
      className={clsx([
        'jpd-btn',
        `jpd-btn--${type}`,
        `jpd-btn--${size}`,
        { 'jpd-btn--full': isFull },
        { 'jpd-btn--disabled': disabled },
        className,
      ])}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}
