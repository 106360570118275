import { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'

import pkg from '../../package.json'

const pkgVersion = pkg.version

async function getVersion() {
  // 开发环境直接返回当前版本
  if (process.env.NODE_ENV === 'development') return pkgVersion

  const res = await fetch(`/version.txt?_t=${Date.now()}`)
  // 版本获取失败时，返回当前版本
  if (!res.ok) return pkg.version

  const text = await res.text()
  return text.trim()
}

/**
 * 检查版本是否发生变化，如果发生变化，那么重新加载页面
 *
 * @returns {boolean} isReloading 是否正在重新加载页面
 */
export function useShouldReload() {
  const { data: version } = useSWRImmutable('version', getVersion, {
    loadingTimeout: 3000,
    shouldRetryOnError: false,
  })
  const shouldReload = !!version && pkgVersion !== version
  // 如果正在获取版本信息或者应该重新加载页面，那么 isReloading 为 true
  const isReloading = !version || shouldReload

  useEffect(() => {
    if (shouldReload) {
      window.location.reload()
    }
  }, [shouldReload])

  return { isReloading }
}
