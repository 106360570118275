import { createContext, useContext, useEffect, useState } from 'react'
import { useLocalStorage, useToggle } from 'react-use'
import useSWRImmutable from 'swr/immutable'

import { useShopId } from '../hooks'
import { getWechatConfig } from '../api'
import { config as globalConfig } from '../utility/config'

export type WechatContextType = {
  isReady: boolean
  isMenuVisible: boolean
  toggleMenuVisible?: (nextValue?: any) => void
  appId?: string
  config?: any
  captchaId: string
  showCaptcha: boolean
}

const WechatContext = createContext<WechatContextType>({
  isReady: false,
  isMenuVisible: true,
  captchaId: globalConfig.captchaId,
  showCaptcha: true,
})

export function WechatProvider({ children }: { children: React.ReactNode }) {
  const { data: config } = useSWRImmutable('Home.Jsapi', () =>
    getWechatConfig(window.location.href)
  )
  const [isReady, setIsReady] = useState(false)
  const [isMenuVisible, toggleMenuVisible] = useToggle(true)
  const [appId, setAppId] = useLocalStorage('appId', '', { raw: true })
  const [captchaId, setCaptchaId] = useState(globalConfig.captchaId)
  const [showCaptcha, setShowCaptcha] = useState(true)

  useEffect(() => {
    if (config) {
      setAppId(config.appId)
      setCaptchaId(config.captcha_appid)
      setShowCaptcha(config.slide_captcha_switch === 1)
      wx.config({
        debug: false,
        appId: config.appId,
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        signature: config.signature,
        jsApiList: config.jsApiList,
        openTagList: ['wx-open-launch-app'],
      })
      wx.ready(() => {
        setIsReady(true)
      })
      wx.error((err) => {
        console.error('微信JS-SDK错误：', err)
      })
    }
  }, [config, setAppId])

  return (
    <WechatContext.Provider
      value={{
        isReady,
        isMenuVisible,
        toggleMenuVisible,
        appId,
        config,
        captchaId,
        showCaptcha,
      }}
    >
      {children}
    </WechatContext.Provider>
  )
}

/**
 * js-sdk 是否已经 ready
 */
export function useWechat() {
  return useContext(WechatContext)
}

/**
 * 隐藏所有非基础按钮接口
 */
export function useWechatHideAllNonBaseMenuItem() {
  const { isReady, toggleMenuVisible } = useWechat()

  useEffect(() => {
    if (isReady) {
      wx.hideAllNonBaseMenuItem()
      toggleMenuVisible?.()
    }
  }, [isReady, toggleMenuVisible])
}

/**
 * 显示所有非基础按钮接口
 */
export function useWechatShowAllNonBaseMenuItem() {
  const { isReady, toggleMenuVisible } = useWechat()

  useEffect(() => {
    if (isReady) {
      wx.showAllNonBaseMenuItem()
      toggleMenuVisible?.()
    }
  }, [isReady, toggleMenuVisible])
}

/**
 * 更新分享卡片信息
 */
export function useWechatShareData(config?: wx.ShareToUserConfig) {
  const { isReady } = useWechat()
  const { shopId, branchId } = useShopId()

  useEffect(() => {
    if (isReady && config) {
      // 链接带上 shop_id 和 branch_id
      if (config.link) {
        const url = new URL(config.link)
        if (shopId) url.searchParams.set('shop_id', shopId)
        if (branchId) url.searchParams.set('branch_id', branchId)
        config.link = url.href
      }

      wx.showAllNonBaseMenuItem()
      wx.updateAppMessageShareData(config)
      wx.updateTimelineShareData(config)

      return () => {
        wx.hideAllNonBaseMenuItem()
      }
    }
  }, [isReady, config, shopId, branchId])
}
