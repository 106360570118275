import { useLayoutEffect } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

const $root = document.getElementById('root')

export function useBodyScrollLock(locked = false, target = $root) {
  useLayoutEffect(() => {
    if (!target) return

    if (locked) {
      disableBodyScroll(target, {
        // 加上 scrollable 类名的元素可以滚动
        allowTouchMove: (el) => {
          let element = el

          while (element && element !== document.body) {
            if (element.classList.contains('scrollable')) {
              return true
            }

            element = element.parentElement as HTMLElement
          }

          return false
        },
      })
    } else {
      enableBodyScroll(target)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [locked, target])
}
