import React, { useState, useEffect } from 'react'
import { Toast, ImagePicker } from 'antd-mobile'
import qs from 'qs'
import * as qiniu from 'qiniu-js'
import { throttle } from 'throttle-debounce'
import { useQuery } from '../../hooks/useQuery'
import { request } from '../../utility/request'
import { config } from '../../utility/config'
import '../cart/address.css'

const PostComment = (props) => {
  const query = useQuery()
  const OgIdCrypto = query.get('og_id')

  const gImg = localStorage.getItem('comment_img') || ''
  const gName = localStorage.getItem('comment_gname') || ''

  const [files, setFiles] = useState([]) //上传成功图片
  const [upToken, setUptoken] = useState([]) //token
  const [lines, setLines] = useState('') //上传数组
  const [count, setCount] = useState(1)
  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])
  useEffect(() => {
    request({
      url: 'Home.GetQiNiuToken&PrefixType=3',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || null,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data) {
          setUptoken(res.data)
        }
      }
    })
  }, [count])

  const handleChange = (f, type, index) => {
    if (type === 'add') {
      let arr_t = f
      if (arr_t.length > 7) {
        Toast.info('不得上传多于七张图片')
        return
      }
      for (var i = 0; i < arr_t.length; i++) {
        let ff = arr_t[i]
        if (!ff.done) {
          var observable = qiniu.upload(
            ff['file'],
            [upToken[i]['key'], ff['file'].type.split('/')[1]].join('.'),
            upToken[i]['token'],
            {
              fname: '',
              params: {},
              mimeType: ['image/png', 'image/jpeg'],
            },
            {
              useCdnDomain: true,
            }
          )
          observable.subscribe(
            () => {},
            () => {},
            (es) => {
              ff['url'] = `https://${config.imageCdn}/` + es.key
              ff['key'] = es.key
              ff['done'] = 1
              setFiles(arr_t)
            }
          )
        }
      }

      setCount((w) => w + 3)
    } else {
      setFiles(f)
    }
  }

  const commentSubmit = throttle(1500, () => {
    if (lines === '') {
      Toast.info('输入您的宝贵评价哦~', 1.2)
      return
    } else if (files.length < 1) {
      Toast.info('上传一些图片记录一下美好时刻吧~', 1.2)
      return
    }
    let fileStr = ''
    for (var s = 0; s < files.length; s++) {
      fileStr += files[s].url + ','
    }

    request({
      url: 'Evaluate.GoodsEvaluate',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || null,
        EvaContent: lines,
        File: fileStr,
        OgIdCrypto,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('提交成功', 1.2, () => props.history.goBack())
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  })

  return (
    <div
      style={{ background: '#f2f2f2', minHeight: '100vh', padding: '20px 0' }}
    >
      <div className="row-line-box" style={{ borderColor: '#DDDDDD' }}>
        <img
          src={gImg}
          alt=""
          style={{ width: 90, height: 90, objectFit: 'cover' }}
        />
        <p style={{ width: '64%', alignSelf: 'flex-start' }}>{gName}</p>
      </div>

      <section style={{ padding: '0 12px' }}>
        <textarea
          style={{
            background: 'transparent',
            width: '100%',
            marginBottom: 20,
            marginTop: 10,
          }}
          value={lines}
          onChange={(d) => setLines(d.target.value)}
          placeholder="宝贝满足你的期待吗？快来上传你的买家秀吧"
        ></textarea>

        <ImagePicker
          files={files}
          onChange={handleChange}
          selectable={files.length < 7}
          multiple={true}
          length={3}
        />
      </section>

      <div className="fixed-bottom-button">
        <button onClick={commentSubmit}>提交</button>
      </div>
    </div>
  )
}
export default PostComment
