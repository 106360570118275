import fetch from '../utility/fetcher'

export type ShopMessageParams = {
  ShopID: string
  Content: string
  OrderCode?: string
}

/**
 * 添加留言
 */
export const createShopMessage = (data: ShopMessageParams) =>
  fetch({
    Url: 'Shop.AddGuestbook',
    Key: localStorage.getItem('c_token'),
    ...data,
  })
