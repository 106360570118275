import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

export type UseCountdownOptions = {
  isRunning?: boolean
  onEnd?: () => void
  onChange?: (time: number) => void
}

/**
 * 使用倒计时的自定义钩子函数。
 * @param countdown 倒计时的初始值(秒)
 * @param isRunning 控制倒计时是否在运行
 * @param onEnd 倒计时结束时的回调函数
 * @param onChange 倒计时每次变化时的回调函数
 * @returns 当前倒计时的剩余时间
 */
export function useCountdown(
  countdown: number,
  { isRunning = true, onEnd, onChange }: UseCountdownOptions = {}
) {
  const [time, setTime] = useState(countdown)

  useEffect(() => {
    setTime(countdown)
  }, [countdown])

  useInterval(
    () => {
      setTime((prevTime) => Math.max(prevTime - 1, 0))
    },
    isRunning && time > 0 ? 1000 : null
  )

  useEffect(() => {
    if (isRunning && time === 0) {
      onEnd?.()
    }
  }, [isRunning, time, onEnd])

  useEffect(() => {
    if (isRunning) {
      onChange?.(time)
    }
  }, [isRunning, time, onChange])

  return time
}
