import fetch from '../utility/fetcher'
import { cRequest } from '../utility/http-instance'
import { getExpiryStorage, setExpiryStorage } from '../utility/expiry-storage'

export type AreaNameParams = {
  province: string
  city: string
  area: string
}

export type Area = {
  id: string | number
  name: string
  _child?: Area[]
}

export type AreaIdResponse = {
  provinceId: string
  cityId: string
  areaId: string
}

/**
 * 根据地区名称获取地区ID
 */
export function getAreaIdByName(data: AreaNameParams) {
  return fetch({ Url: 'Area.GetAreaIdByName', ...data })
}

/**
 * 获取省市区地址列表
 */
export async function getAreaList() {
  if (getExpiryStorage('area_list')) {
    const areaList = getExpiryStorage('area_list') as Area[]
    return Promise.resolve(areaList)
  }

  const areaList = await cRequest.post<Area[]>('Area.AreaList', { platform: 4 })
  const simplifiedAreas = simplifyAreas(areaList)
  setExpiryStorage('area_list', simplifiedAreas, 1800)
  return simplifiedAreas
}

/**
 * 根据经纬度获取地区ID
 *
 * @param {number} longitude 经度
 * @param {number} latitude 纬度
 */
export function getAreaIdByCoordinates(longitude: number, latitude: number) {
  return cRequest.post<AreaIdResponse>('Area.GetAreaIdByCoordinates', {
    Longitude: longitude,
    Latitude: latitude,
  })
}

/**
 * 简化地址列表
 *
 * @param {Area[]} areaList 地址列表
 */
function simplifyAreas(areaList: Area[]): Area[] {
  return areaList.map((area) => {
    const { id, name, _child } = area
    return {
      id,
      name,
      _child: _child ? simplifyAreas(_child) : undefined,
    }
  })
}
