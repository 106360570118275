import { useSWRConfig } from 'swr'

/**
 * @see https://swr.vercel.app/docs/advanced/cache#mutate-multiple-keys-from-regex
 */
export function useMatchDelete() {
  const { cache } = useSWRConfig()

  return (matcher: RegExp) => {
    if (!(cache instanceof Map)) {
      throw new Error(
        'useMatchDelete requires the cache provider to be a Map instance'
      )
    }

    const keys = Array.from(cache.keys()).filter((key) => matcher.test(key))

    keys.forEach((key) => cache.delete(key))
  }
}
