//触底分页请求
export default function handlePages() {
  const getScrollTop = () => {
    var scrollTop = 0
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop
    } else if (document.body) {
      scrollTop = document.body.scrollTop
    }
    return scrollTop
  }
  const getClientHeight = () => {
    var clientHeight = 0
    if (document.body.clientHeight && document.documentElement.clientHeight) {
      clientHeight = Math.min(
        document.body.clientHeight,
        document.documentElement.clientHeight
      )
    } else {
      clientHeight = Math.max(
        document.body.clientHeight,
        document.documentElement.clientHeight
      )
    }
    return clientHeight
  }
  const getScrollHeight = () =>
    Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)

  if (getScrollTop() + getClientHeight() + 60 >= getScrollHeight()) {
    return true
  } else {
    return false
  }
}
