import { useState, useEffect, useCallback, useMemo } from 'react'
import { ActivityIndicator, Toast } from 'antd-mobile'
import qs from 'qs'

import { Button, Sku } from '../../components'
import { SwiperCard, ListCard, SelectedList } from './components'
import { ShowEditModal } from '../../utility/modal'
import { useQuery, useShopId } from '../../hooks'
import { reLogin, request } from '../../utility/request'
import { config } from '../../utility/config'
import './index.css'

export type ExchangeGoods = {
  goods_id: string
  selected_list: any[]
  [key: string]: any
}

export type ExchangeData = {
  status: number
  top_tip: string
  start_time: string
  end_time: string
  goods_list: any[]
  exchange_tip: string
  selected_num: number
  max_num: number
  exchange_goods_list: ExchangeGoods[]
}

const initialData = {
  status: 0,
  top_tip: '',
  start_time: '',
  end_time: '',
  goods_list: [],
  exchange_tip: '',
  selected_num: 0,
  max_num: 0,
  exchange_goods_list: [],
}

const initialExchangeGoodsDetail = {
  color_list: [],
  goods_spec_list: [],
}

export default function Exchange({ history }: any) {
  const [data, setData] = useState<ExchangeData>(initialData)
  const [loading, setLoading] = useState(true)
  // 当前操作中的换购商品 index
  const [currentIndex, setCurrentIndex] = useState(0)
  const [skuPopupVisible, setSkuPopupVisible] = useState(false)
  const [selectedPopupVisible, setSelectedPopupVisible] = useState(false)
  // 当前换购商品的详情
  const [exchangeGoodsDetail, setExchangeGoodsDetail] = useState(
    initialExchangeGoodsDetail
  )

  // Query
  const query = useQuery()
  const id = query.get('id') || ''

  // 全局
  const key = localStorage.getItem('c_token') || ''
  const { shopId, branchId } = useShopId()

  // 当前操作中的换购商品
  const currentGoods = useMemo(
    () => data.exchange_goods_list[currentIndex],
    [data, currentIndex]
  )

  // 获取页面详情 API
  const getExchange = useCallback(() => {
    return request({
      url: 'GoodsExchange.ActivityDetail',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        ShopID: shopId,
        ID: id,
      }),
    }).then((res) => {
      if (res.flag === 200) {
        return res.data
      } else if (res.flag === 401) {
        Toast.info('登录信息已过期，请重新登录', 1, () => {
          reLogin(() =>
            history.push(
              `/login?shop_id=${localStorage.getItem(
                'shop_id'
              )}&branch_id=${localStorage.getItem('branch_id')}`
            )
          )
        })
        throw res.data.msg
      } else {
        history.replace(`/?shop_id=${shopId}&branch_id=${branchId}`)
      }
    })
  }, [key, shopId, branchId, id, history])

  // 获取商品详情 API
  const getGoodsDetail = useCallback(
    (goodsId: string) => {
      const timer = setTimeout(() => {
        Toast.loading('加载中', 0)
      }, 250)

      return request({
        url: 'Selection.GoodsDetails',
        method: 'POST',
        body: qs.stringify({
          Key: key,
          ShopID: shopId,
          GoodsID: goodsId,
          ActivityType: '7',
        }),
      })
        .then((res) => {
          if (res.flag === 200) {
            return res.data
          } else {
            throw new Error(res.msg)
          }
        })
        .catch((err) => {
          Toast.fail(err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          Toast.hide()
        })
    },
    [key, shopId]
  )

  // 修改标题
  useEffect(() => {
    document.title = '活动商品清单'

    return () => {
      document.title = config.title as string
    }
  }, [])

  // 首次获取页面详情
  useEffect(() => {
    getExchange().then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [getExchange])

  // 点击加入购物车，打开 SKU 弹窗
  function handleCartAdd(index: number, goodsId: string) {
    setCurrentIndex(index)
    getGoodsDetail(goodsId).then((data) => {
      setExchangeGoodsDetail(data)
      setSkuPopupVisible(true)
    })
  }

  // 关闭 SKU 弹窗
  function handleSkuPopupClose() {
    setSkuPopupVisible(false)
    getExchange().then(setData)
  }

  // 查看已选商品
  function handleSelectedDisplay(index: number) {
    setCurrentIndex(index)
    setSelectedPopupVisible(true)
  }

  // 增减已选商品数量
  function handleCartNumChange(value: any, cartId: string) {
    return request({
      url: 'MultiCart.EditCartquantity',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        ShopID: shopId,
        CartID: cartId,
        GoodsNum: value,
      }),
    }).then((res) => {
      if (res.flag !== 200) {
        Toast.fail(res.msg)
        throw new Error(res.msg)
      }
    })
  }

  // 删除购物车商品
  function handleCartDelete(cartId: string) {
    return request({
      url: 'MultiCart.DelCart',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        ShopID: shopId,
        CartID: cartId,
      }),
    }).then((res) => {
      if (res.flag !== 200) {
        Toast.fail(res.msg)
        throw new Error(res.msg)
      }
    })
  }

  // 关闭“查看已选商品”的弹窗
  function handleSelectedPopupClose() {
    setSelectedPopupVisible(false)
    getExchange().then(setData)
  }

  if (loading) {
    return (
      <div className="exchange">
        <ActivityIndicator animating toast text="加载中" />
      </div>
    )
  }

  return (
    <div className="exchange">
      <div className="main-card">
        {/* 换购标题与时间 */}
        <div className="title">{data.top_tip}</div>
        <div className="time">
          <div>活动时间：</div>
          <div>
            {data.start_time} 至 {data.end_time}
          </div>
        </div>

        {/* 换购主商品列表 */}
        <div className="swiper-list">
          {data.goods_list.map((goods, i) => (
            <SwiperCard key={i} {...goods} />
          ))}
        </div>
      </div>

      <div className="exchange-card">
        {/* 换购提示与数量 */}
        <div className="count">
          <div className="count-desc">{data.exchange_tip}</div>
          {data.status === 1 ? (
            <span className="count-num">
              <span className="count-num-less">{data.selected_num}</span>/
              {data.max_num}
            </span>
          ) : null}
        </div>

        {/* 换购商品列表 */}
        <div className="list">
          {data.exchange_goods_list.map((goods, i) => (
            <ListCard
              key={goods.goods_id}
              activityId={id}
              {...goods}
              onCartAdd={() => handleCartAdd(i, goods.goods_id)}
              onSelectedDisplay={() => handleSelectedDisplay(i)}
            />
          ))}
        </div>
      </div>

      {/* 购物车按钮，底部固定 */}
      <div className="cart">
        <Button
          className="cart-btn"
          size="large"
          isFull
          onClick={() => history.push('/cart')}
        >
          去购物车结算
        </Button>
      </div>

      {/* SKU 弹窗 */}
      <ShowEditModal
        anType="slide-up"
        popupVal={true}
        visible={skuPopupVisible}
        handleCancel={handleSkuPopupClose}
        formContent={
          <Sku
            goodsInfo={currentGoods}
            goodsDetails={exchangeGoodsDetail}
            maxNum={data.max_num - data.selected_num}
            afterAdd={handleSkuPopupClose}
          />
        }
      />

      {/* 查看已选商品 */}
      <ShowEditModal
        anType="slide-up"
        popupVal={true}
        visible={selectedPopupVisible}
        handleCancel={handleSelectedPopupClose}
        formContent={
          <SelectedList
            data={currentGoods?.selected_list}
            available={data.max_num - data.selected_num}
            onNumberChange={handleCartNumChange}
            onDelete={handleCartDelete}
            onConfirm={handleSelectedPopupClose}
          />
        }
      />
    </div>
  )
}
