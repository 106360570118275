import { useLayoutEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import styles from './index.module.css'

export type FloatBottomProps = {
  className?: string
  children?: React.ReactNode
}

export function FloatBottom({ className, children }: FloatBottomProps) {
  const floatRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)

  useLayoutEffect(() => {
    if (!floatRef.current) {
      return
    }

    setHeight(floatRef.current.offsetHeight)
  }, [])

  return (
    <>
      <div style={{ height }}></div>
      <div ref={floatRef} className={clsx(styles['float-bottom'], className)}>
        {children}
      </div>
    </>
  )
}
