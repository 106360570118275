import { useState } from 'react'
import clsx from 'clsx'
import { ContactModal } from '../contact-modal'
import './index.css'

export type EmptyProps = {
  className?: string
  image?: string
  text?: string | string[]
  showContact?: boolean
  shopId: string
}

export function Empty({
  className,
  image,
  text,
  showContact = false,
  shopId,
}: EmptyProps) {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className={clsx('empty', className)}>
      {image ? <img className="empty__image" src={image} alt="" /> : null}
      {text ? (
        <div className="empty__text">
          {typeof text === 'string'
            ? text
            : text.map((item) => <div key={item}>{item}</div>)}
        </div>
      ) : null}
      {showContact ? (
        <div className="empty__contact" onClick={() => setModalVisible(true)}>
          联系店主
        </div>
      ) : null}

      <ContactModal
        shopId={shopId}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}
