import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'

export type GetKey = (page: number) => any

export type InfiniteConfig = SWRInfiniteConfiguration & {
  pageSize?: number
}

export function useRequestInfinite(
  getKey: GetKey,
  config: InfiniteConfig = {}
) {
  const { pageSize = 10, ...rest } = config
  const { data, error, size, setSize, mutate, isValidating } = useSWRInfinite(
    (index) => getKey(index + 1) || null,
    {
      revalidateFirstPage: false,
      ...rest,
    }
  )

  // 列表数据处理
  const list = data ? [].concat(...data) : []
  const isLoadingInitialData = !data && !error
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < pageSize)

  function loadMore() {
    setSize((size) => size + 1)
  }

  return {
    data,
    list,
    isLoading: isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    isValidating,
    loadMore,
    size,
    setSize,
    mutate,
    error,
  }
}
