import { ActivityIndicator } from 'antd-mobile'
import QueueAnim from 'rc-queue-anim'

import { GoodsCard } from '../components'
import { useReachEnd, useRequestInfinite, useShopId, useTitle } from '../hooks'

export default function Hotsale() {
  const { shopId } = useShopId()
  const { list, isLoadingMore, isReachingEnd, loadMore } = useRequestInfinite(
    (PageIndex) => ({
      Url: 'Selection.HotRecommend',
      ShopID: shopId,
      PageSize: 10,
      PageIndex,
    })
  )

  useTitle('热卖爆款')

  useReachEnd(
    () => {
      if (isLoadingMore || isReachingEnd) return

      loadMore()
    },
    { threshold: 250 }
  )

  return (
    <QueueAnim type={['left', 'right']} leaveReverse>
      <ul
        key="hotsale__list"
        style={{ display: 'flex', flexWrap: 'wrap', padding: 4 }}
      >
        {list.map((goods: any) => (
          <GoodsCard key={goods.goods_id} {...goods} />
        ))}
      </ul>
      <div key="hotsale__status" className="indicator">
        {isLoadingMore && <ActivityIndicator text="加载中..." />}
        {isReachingEnd && '到底咯~'}
      </div>
    </QueueAnim>
  )
}
