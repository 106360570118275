import { useState, useEffect, useMemo, useRef } from 'react'
import { request } from '../../utility/request'
import qs from 'qs'
import './packageDetail.css'
import { Toast } from 'antd-mobile'
import { useQuery } from '../../hooks/useQuery'
import '../list/list.css'
import './order.css'

export default function PackageDetail(props) {
  const {
    match: {
      params: { id },
    },
  } = props
  const [list, setList] = useState([])
  const [logList, setLogList] = useState([])
  const [logIndex, setLogIndex] = useState(0)
  const inputRef = useRef(null)
  const query = useQuery()
  const useMulti = query.get('use_multi') === '1'

  useEffect(() => {
    id &&
      request({
        url: useMulti ? 'MultiMemberOrder.Express' : 'Order.Express',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token') || null,
          OrderIdCrypto: id,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          setList(res.data)
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
  }, [id, useMulti])

  useEffect(() => {
    list.length > 0 &&
      request({
        url: useMulti ? 'MultiMemberOrder.ExpressQuery' : 'Order.ExpressQuery',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token') || null,
          ShippingCode: list[logIndex]['shipping_code'],
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          setLogList(res.data.data)
        } else {
          setLogList([])
          res.msg && Toast.fail(res.msg)
        }
      })
  }, [logIndex, list, useMulti])

  const doCopy = () => {
    setTimeout(() => {
      inputRef.current.select()
      document.execCommand('copy')
      Toast.success('', 1)
    }, 100)
  }

  const totalNum = useMemo(() => {
    if (list.length > 0) {
      let num = 0
      const goods = list[logIndex]['goods'] || []
      for (let i = 0; i < goods.length; i++) {
        num += parseInt(goods[i]['send_num'])
      }
      return num
    }
    return 0
  }, [logIndex, list])

  return (
    <div>
      <div className="navbox">
        {list.length > 0 && (
          <input
            className="copy-input"
            value={list[logIndex]['shipping_code']}
            ref={inputRef}
          />
        )}
        <ul>
          {list.length > 0 &&
            list.map((item, indx) =>
              logIndex === indx ? (
                <li className="class-tab cur" key={indx}>
                  {item.title}
                </li>
              ) : (
                <li
                  className="class-tab"
                  key={indx}
                  onClick={() => setLogIndex(indx)}
                >
                  {item.title}
                </li>
              )
            )}
        </ul>
      </div>
      {list.length === 0 && (
        <p style={{ paddingTop: 100, textAlign: 'center' }}>
          暂无您的包裹信息~
        </p>
      )}

      {list[logIndex] && (
        <div
          className="pale-row-line"
          style={{
            padding: '0 10px',
            marginTop: 30,
            justifyContent: 'flex-start',
          }}
        >
          <p>
            {list[logIndex].express_name}：{list[logIndex].shipping_code}
          </p>
          <button className="copy-btn" onClick={doCopy}>
            复制
          </button>
        </div>
      )}

      <ul className="package-list-inline">
        {list[logIndex] &&
          list[logIndex]['goods'].map((item, idx) => (
            <li className="package-box" key={idx}>
              <div className="package-bar">
                <div className="pack-img-wrap">
                  <img src={item.goods_image} alt="" />
                  <span>已发{item.send_num}件</span>
                </div>
              </div>
            </li>
          ))}
      </ul>
      <p style={{ padding: '0 15px', textAlign: 'right' }}>
        合计商品：{totalNum}
      </p>
      <div style={{ padding: '10px 0 60px 0' }}>
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imgSrc} alt="" style={{ width: 60, height: 50 }} />
                    <div style={{ textAlign: 'left', alignItems: 'center', padding: '7px 0 0 15px' }}>
                        <p>{orderGoods && orderGoods.express_name}</p>
                        <p>快递单号：{orderGoods && orderGoods.shipping_code}</p>
                    </div>
                </div> */}
        <ul className="steps-wrap">
          {logList && logList.length > 0 ? (
            <>
              <div className="sector-line"></div>
              {logList.map((item, idx) => (
                <li key={idx}>
                  <span>{item.time}</span>
                  <div className={'step-circle step-plaform'}></div>
                  <p>{item.context}</p>
                </li>
              ))}
            </>
          ) : (
            <p style={{ marginTop: 60, textAlign: 'center' }}>
              暂无物流信息~请稍后查询
            </p>
          )}
        </ul>
      </div>
    </div>
  )
}
