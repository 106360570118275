import { Toast } from 'antd-mobile'
import { commonUrl, version } from './config'

export type RequestConfig = {
  Url: string
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT'
  // 返回的数据可能不是一个数组，这时我们直接指定能够取到的数组的 key
  listKey?: string
  [key: string]: any
}

export default async function fetcher<JSON = any>(
  params: RequestConfig
): Promise<JSON> {
  const storageShopId = localStorage.getItem('shop_id')
  const storageBranchId = localStorage.getItem('branch_id')
  const { Url, method, listKey, ...body } = params
  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  })

  // 所有请求都添加 ShopID 传参
  // 没有传入 ShopID 并且本地存有 ShopID，则会自动拼接
  if (typeof body.ShopID === 'undefined' && storageShopId) {
    body.ShopID = storageShopId
  }

  // 添加 BranchID 传参
  if (typeof body.BranchID === 'undefined' && storageBranchId) {
    body.BranchID = storageBranchId
  }

  // 版本号
  body.Version = version

  let url = commonUrl + Url
  const options: RequestInit = {
    method: method || 'POST',
    headers,
  }

  if (method?.toLowerCase() !== 'get') {
    options.body = new URLSearchParams(body)
  } else if (Object.keys(body).length > 0) {
    url += '&' + new URLSearchParams(body).toString()
  }

  try {
    const res = await fetch(url, options)
    const data = await res.json()

    if (data.flag !== 200) {
      // 400 店铺链接已失效,请重新获取
      // 999 此链接已过期
      if ([400, 999].indexOf(data.flag) > -1) {
        Toast.fail(data.msg)
      }

      throw data
    }

    return !Array.isArray(data.data) && listKey ? data.data[listKey] : data.data
  } catch (e) {
    return Promise.reject(e)
  }
}
