import { useState, useEffect, useRef } from 'react'

/**
 * 使用防抖功能的自定义 Hook
 *
 * @param value - 要进行防抖处理的值
 * @param delay - 防抖延迟时间（毫秒）
 * @returns 防抖处理后的值
 */
export function useDebounceValue<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [value, delay])

  return debouncedValue
}
