const countdown = (t) => {
  var second = t
  // 天数位
  var day = Math.floor(second / 3600 / 24)
  // var dayStr = day.toString();
  // if (dayStr.length == 1) dayStr = '0' + dayStr;
  // 小时位
  var hr = Math.floor((second - day * 3600 * 24) / 3600)
  var hrStr = (day * 24 + hr).toString()
  if (hrStr.length === 1) hrStr = '0' + hrStr
  // 分钟位
  var min = Math.floor((second - day * 3600 * 24 - hr * 3600) / 60)
  var minStr = min.toString()
  if (minStr.length === 1) minStr = '0' + minStr
  // 秒位
  var sec = second - day * 3600 * 24 - hr * 3600 - min * 60
  var secStr = sec.toString()
  if (secStr.length === 1) secStr = '0' + secStr

  return { h: hrStr, m: minStr, s: secStr }
}

const TimeLineToDate = (n) => {
  let date = new Date(n * 1000)
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let H = ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
  let MM =
    ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
  return Y + M + D + H + MM
}
export { countdown, TimeLineToDate }
