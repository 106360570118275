import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Toast } from 'antd-mobile'
import clsx from 'clsx'
import qs from 'qs'
import { Button, Stepper } from '..'
import { useQuery } from '../../hooks/useQuery'
import { request } from '../../utility/request'
import './index.css'

export type SkuProps = {
  goodsInfo: any
  goodsDetails: any
  maxNum: number
  afterAdd?: () => void
}

// 判断两个数组是否含有相同元素
function isSameArray<T extends string | number>(a: T[], b: T[]) {
  return (
    a.every((item) => b.includes(item)) && b.every((item) => a.includes(item))
  )
}

export function Sku({
  goodsInfo = {},
  goodsDetails = {},
  maxNum,
  afterAdd = () => {},
}: SkuProps) {
  const [storageList, setStorageList] = useState<any[]>([])
  const [currentGoodsNum, setCurrentGoodsNum] = useState(1)
  const [currentSpecs, setCurrentSpecs] = useState<string[]>(
    goodsDetails.color_list.map(() => '')
  )

  // query
  const query = useQuery()
  const activityId = query.get('id') || ''

  // 用于传参 以逗号分隔的 goods_id 字符串
  const goodsIdList = useMemo(
    () =>
      goodsDetails.goods_spec_list
        .filter(({ is_del }: any) => is_del === 0)
        .map(({ goods_id }: any) => goods_id)
        .join(','),
    [goodsDetails.goods_spec_list]
  )

  // 重构成用于展示的列表字段
  const globalSpecList: any[] = useMemo(
    () =>
      goodsDetails.color_list.map((item: any) => ({
        id: item.father_id.toString(),
        label: item.name,
        items: item.spec_inner.map((inner: any) => ({
          id: inner.id.toString(),
          fatherId: inner.father_id.toString(),
          name: inner.name,
        })),
      })),
    [goodsDetails]
  )

  // 由详情和库存重组而成的商品数据列表
  const globalGoodsList: any[] = useMemo(() => {
    if (storageList.length === 0) {
      return []
    }

    // 拼成 id 为 key，库存和规格为 value 的对象
    const storageMap = storageList.reduce((acc, cur) => {
      acc[cur.goods_id] = cur.goods_storage
      return acc
    }, {})

    return goodsDetails.goods_spec_list.map((item: any) => ({
      id: item.goods_id,
      image: item.goods_image,
      price: item.goods_price,
      storage: storageMap[item.goods_id] || item.goods_storage,
      spec: Object.keys(item.goods_spec || []),
      preSale: item.pre_sale,
      preSaleTip: item.pre_sale_tip,
    }))
  }, [goodsDetails, storageList])

  // 当前规格的名称
  const currentSpecNames = useMemo(
    () =>
      currentSpecs
        .map(
          (specId, index) =>
            globalSpecList[index].items.find(({ id }: any) => id === specId)
              ?.name
        )
        .filter(Boolean)
        .join(','),
    [currentSpecs, globalSpecList]
  )

  // 当前选中规格的商品
  // TODO: 只有单行 sku 的情况
  const currentGoods = useMemo(
    () =>
      globalGoodsList.find((goods, index) =>
        globalSpecList.length === 0
          ? index === 0 // 只有一个 sku
          : isSameArray(goods.spec, currentSpecs)
      ),
    [globalGoodsList, globalSpecList, currentSpecs]
  )

  // 检测规格是否可点击
  const checkSpecDisabled = useCallback(
    (specId: string) => {
      for (const rowIndex in globalSpecList) {
        for (const spec of globalSpecList[rowIndex].items) {
          // 当前属性是否在已选中的规格中
          if (currentSpecs[rowIndex] === spec.id) {
            continue
          }

          // 构造当前属性和其他已选规格的组合
          const nextSpecs = [...currentSpecs]
          nextSpecs[rowIndex] = specId

          // 查找当前拼凑出的规格的商品
          const nextGoods = globalGoodsList.find((goods) =>
            isSameArray(goods.spec, nextSpecs)
          )

          // 判断当前商品的库存是否为 0，是则禁用
          if (nextGoods?.storage === 0) {
            return true
          }
        }
      }

      return false
    },
    [currentSpecs, globalSpecList, globalGoodsList]
  )

  // 获取库存
  useEffect(() => {
    // 没有传参 or 规格没有全部选中时不重新请求库存
    if (!goodsIdList || !currentSpecs.every(Boolean)) return

    request({
      url: 'Selection.GoodsSpecStorage',
      method: 'POST',
      body: qs.stringify({
        GoodsIdList: goodsIdList,
      }),
    }).then((res) => {
      if (res.flag === 200) {
        setStorageList(res.data.goods_storage_list)
      }
    })
  }, [goodsIdList, currentSpecs])

  // 切换 SKU 之后，如果之前的购买数量大于库存，则设置为库存（不为 0）
  useEffect(() => {
    if (currentGoods?.storage < currentGoodsNum && currentGoods?.storage > 0) {
      setCurrentGoodsNum(currentGoods.storage)
    }
  }, [currentGoods, currentGoodsNum])

  // 点击规格标签
  function handleSpecClick(specId: string, rowIndex: number) {
    setCurrentSpecs((prevSpecs) =>
      prevSpecs.map((v, i) =>
        i === rowIndex ? (v !== specId ? specId : '') : v
      )
    )
  }

  // 加入购物车
  function handleCartAdd() {
    if (!currentGoods) {
      return Toast.info('请选择规格', 1, undefined, false)
    }

    if (currentGoods.storage === 0) {
      return Toast.info('库存不足', 1, undefined, false)
    }

    request({
      url: 'MultiCart.AddCart',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || '',
        ShopID: localStorage.getItem('shop_id') || '',
        GoodsID: currentGoods.id,
        GoodsNum: currentGoodsNum.toString(),
        ActivityID: activityId,
        ActivityType: goodsInfo.activity_type || 0,
      }),
    }).then((res) => {
      if (res.flag === 200) {
        Toast.success('添加购物车成功~', 1, () => {
          afterAdd()
        })
      } else {
        Toast.fail(res.msg)
      }
    })
  }

  function handlePreviewImage() {
    window.wx.previewImage?.({
      urls: [currentGoods?.image || goodsInfo.goods_image],
    })
  }

  return (
    <div className="sku-container">
      <div className="sku-head">
        {/* 商品图 */}
        <div className="sku-image-container" onClick={handlePreviewImage}>
          <img
            className="sku-image"
            alt=""
            src={currentGoods?.image || goodsInfo.goods_image}
          />
        </div>
        <div>
          {/* 价格 */}
          <div className="sku-price">
            ¥{currentGoods?.price || goodsInfo.goods_price}
          </div>
          {/* 库存 */}
          <div className="sku-storage">库存：{currentGoods?.storage || 0}</div>
          {/* 预售提示 */}
          {currentGoods?.storage && currentGoods?.preSaleTip ? (
            <div
              className={
                currentGoods?.preSale === 2
                  ? 'theme-color'
                  : 'theme-yellow-color'
              }
            >
              {currentGoods?.preSaleTip}
            </div>
          ) : null}
          {/* 已选规格 */}
          <div className="sku-selected">已选择：{currentSpecNames}</div>
        </div>
      </div>

      <div className="sku-body scrollable">
        {globalSpecList.map((row, rowIndex) => (
          <Fragment key={row.id}>
            <div className="sku-label">{row.label}</div>
            <div className="sku-specs">
              {row.items.map((item: any) => (
                <Button
                  key={item.id}
                  className={clsx('sku-spec', {
                    'sku-spec--disabled':
                      checkSpecDisabled(item.id) &&
                      !currentSpecs.includes(item.id.toString()),
                  })}
                  type={
                    currentSpecs.includes(item.id.toString())
                      ? 'primary'
                      : 'plain'
                  }
                  onClick={() => handleSpecClick(item.id, rowIndex)}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          </Fragment>
        ))}
      </div>

      <div className="sku-num">
        <div className="sku-label">购买数量</div>
        <Stepper
          disabled={currentGoods?.storage === 0}
          min={1}
          max={Math.min(currentGoods?.storage || 1, maxNum)}
          defaultValue={currentGoodsNum}
          onChange={setCurrentGoodsNum}
        />
      </div>

      <div className="sku-action">
        <Button size="large" isFull onClick={handleCartAdd}>
          加入购物车
        </Button>
      </div>
    </div>
  )
}
