import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ActivityIndicator, Modal, Toast } from 'antd-mobile'
import { Button } from '../button'
import { useRequest } from '../../hooks/useRequest'
import { showConfirm } from '../../utility/modal'
import { reLogin } from '../../utility/request'
import './index.css'

type ShopMemberInfoProps = {
  shop_logo: string
  shop_name: string
  wx_qrcode: string
  member_mobile: string
  guestbook_switch?: number | string
}

export type ContactModalProps = {
  shopId: string
  branchId?: string
  orderCode?: string
  visible: boolean
  onClose: () => void
}

export function ContactModal({
  shopId,
  branchId = '',
  orderCode,
  visible,
  onClose,
}: ContactModalProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const location = useLocation()

  const { data, error, isLoading } = useRequest<ShopMemberInfoProps>(
    visible
      ? {
          Url: 'Shop.ShopMemberInfo',
          ShopID: shopId,
          BranchID: branchId,
          Key: localStorage.getItem('c_token'),
        }
      : null
  )

  // 未登录
  useEffect(() => {
    if (!error || error.flag !== 401) return

    onClose()
    showConfirm('', '您还未登录，是否登录？', () => {
      const { pathname, search } = location
      reLogin(
        () =>
          history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          ),
        `${pathname}${search}`.replace(/\//, '')
      )
    })
  }, [error, history, shopId, onClose, location])

  // 复制手机号
  function handleMobileCopy() {
    inputRef.current?.select()
    document.execCommand('copy')
    Toast.success('复制成功', 1.5)
  }

  // 给店主留言
  function handleCreateMessage() {
    let url = `/message?shop_id=${shopId}`

    if (orderCode) {
      url += `&order_code=${orderCode}`
    }

    history.push(url)
  }

  if (error) {
    return null
  }

  if (isLoading) {
    return <ActivityIndicator toast />
  }

  return (
    <Modal
      closable
      transparent
      animated={false}
      visible={visible}
      onClose={onClose}
    >
      <div className="contact__base">
        <img className="contact__logo" src={data?.shop_logo} alt="" />
        <span className="contact__name">{data?.shop_name}</span>
      </div>

      {data?.wx_qrcode ? (
        <div className="contact__qr">
          <img className="contact__qrcode" src={data?.wx_qrcode} alt="" />
          <div className="contact__qrcode__tip">长按识别二维码</div>
        </div>
      ) : data?.member_mobile ? (
        <div className="contact__mobile">
          <div>您若遇到问题？</div>
          <div>可通过下方电话，联系到我！</div>

          <div className="contact__mobile__tip">我的手机号：</div>
          <div>
            <a
              className="contact__mobile__number"
              href={`tel:${data.member_mobile}`}
            >
              {data.member_mobile}
            </a>
            <span className="contact__mobile__copy" onClick={handleMobileCopy}>
              复制
            </span>
          </div>

          <input
            className="contact__mobile__input"
            ref={inputRef}
            value={data.member_mobile}
            readOnly
          />
        </div>
      ) : Number(data?.guestbook_switch) === 1 ? (
        <div className="contact__mobile">
          <div>您若遇到问题？</div>
          <div>可通过下方留言，向店主反馈问题。</div>
        </div>
      ) : null}

      {Number(data?.guestbook_switch) === 1 && (
        <Button
          type="plain"
          className="contact__btn"
          onClick={handleCreateMessage}
        >
          联系不上店主，点这里留言！
        </Button>
      )}
    </Modal>
  )
}
