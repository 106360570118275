import { Modal } from 'antd-mobile'

import { relogin } from './utils'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'
type RequestData = Record<string, string | number | object>
type ResponseData<T = unknown> = {
  flag: number
  data: T
  msg: string | null
}

type HttpClientConfig = {
  /**
   * 基础URL
   */
  baseURL: string
  /**
   * 超时时间
   * @default 0
   */
  timeout?: number
  /**
   * 本地存储token所使用的key，用于处理登录失效状态
   */
  tokenKey?: string
  /**
   * 是否自动重新登录
   */
  autoRelogin?: boolean
  /**
   * 请求头
   */
  headers?: HeadersInit
}

/**
 * 默认请求头
 */
const defaultHeaders: HeadersInit = {
  'Content-Type': 'application/x-www-form-urlencoded',
}

/**
 * HTTP错误
 */
class HTTPError extends Error {
  response?: ResponseData
  constructor(message: string, response?: ResponseData) {
    super(message)
    this.response = response
  }
}

/**
 * 网络请求类
 */
class HttpClient {
  config: HttpClientConfig
  constructor(config: HttpClientConfig) {
    this.config = {
      baseURL: config.baseURL,
      timeout: config.timeout,
      tokenKey: config.tokenKey,
      autoRelogin: config.autoRelogin,
      headers: { ...defaultHeaders, ...config.headers },
    }
  }

  /**
   * 发送网络请求
   * @param method HTTP方法
   * @param url 请求的URL
   * @param data 请求数据
   */
  async request<T>(method: HttpMethod, url: string, data?: RequestData) {
    const { baseURL, timeout = 0, tokenKey, autoRelogin, headers } = this.config
    const controller = new AbortController()
    const signal = controller.signal

    let timeoutId: NodeJS.Timeout | null = null
    if (timeout > 0) {
      timeoutId = setTimeout(() => {
        controller.abort()
      }, timeout)
    }

    let body: string | undefined
    if ((method === 'POST' || method === 'PUT') && data) {
      if (
        (headers as Record<string, string>)?.['Content-Type'] ===
        'application/x-www-form-urlencoded'
      ) {
        const params = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            typeof value === 'object' ? JSON.stringify(value) : String(value),
          ])
        )
        body = new URLSearchParams(params).toString()
      } else {
        body = JSON.stringify(data)
      }
    }

    if ((method === 'GET' || method === 'DELETE') && data) {
      const newBaseURL =
        baseURL.startsWith('http://') || baseURL.startsWith('https://')
          ? baseURL
          : window.location.protocol + baseURL
      const urlObj = new URL(newBaseURL)
      const params = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        ])
      )
      const symbol = urlObj.search ? '&' : '?'
      url += symbol + new URLSearchParams(params).toString()
    }

    const options: RequestInit = {
      method,
      headers,
      body,
      signal,
    }

    try {
      const res = await fetch(baseURL + url, options)

      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      if (!res.ok) {
        throw new HTTPError('服务器开小差', {
          flag: res.status,
          data: null,
          msg: res.statusText,
        })
      }

      const data = (await res.json()) as ResponseData<T>

      if (data.flag !== 200) {
        if (data.flag === 401 && tokenKey) {
          localStorage.removeItem(tokenKey)
          if (autoRelogin) {
            relogin()
          } else {
            Modal.alert('提示', '登录失效，请重新登录', [
              { text: '取消' },
              { text: '确定', onPress: () => relogin() },
            ])
          }
        }

        throw new HTTPError(data.msg || '服务器开小差', data)
      }

      return data.data
    } catch (error) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      throw error
    }
  }

  /**
   * 发送GET请求
   * @param url 请求的URL
   * @param params 请求参数
   */
  get<T>(url: string, params?: RequestData) {
    return this.request<T>('GET', url, params)
  }

  /**
   * 发送POST请求
   * @param url 请求的URL
   * @param data 请求数据
   */
  post<T>(url: string, data?: RequestData) {
    return this.request<T>('POST', url, data)
  }

  /**
   * 发送PUT请求
   * @param url 请求的URL
   * @param data 请求数据
   */
  put<T>(url: string, data?: RequestData) {
    return this.request<T>('PUT', url, data)
  }

  /**
   * 发送DELETE请求
   * @param url 请求的URL
   * @param params 请求参数
   */
  delete<T>(url: string, params?: RequestData) {
    return this.request<T>('DELETE', url, params)
  }
}

/**
 * 使用指定的配置创建一个新的HttpClient实例
 * @param config 网络请求的配置
 * @returns 一个新的HttpClient实例
 */
export function create(config: HttpClientConfig) {
  const instance = new HttpClient(config)
  return instance
}
