import { Link } from 'react-router-dom'
import { Icon } from 'antd-mobile'

import { Button } from '../../../../components'
import { useShopId } from '../../../../hooks'
import { PriceTag } from '..'
import './index.css'

export function ListCard(props: any) {
  const {
    goods_id,
    goods_image,
    goods_name,
    goods_price,
    selected_list,
    goods_storage,
    can_select,
    activityId,
    onCartAdd,
    onSelectedDisplay,
  } = props

  // 库存不足
  const outOfStock = goods_storage === 0
  // 购物车按钮是否禁用
  const addBtnDisabled = can_select === 0

  const { shopId, branchId } = useShopId()
  const goodsPath = `/goods?goods_id=${goods_id}&shop_id=${shopId}&branch_id=${branchId}&activity_id=${activityId}`

  return (
    <>
      <div className="list-card">
        <div className="list-cover">
          <Link to={goodsPath}>
            <img className="list-image" alt="" src={goods_image} />
          </Link>
          {outOfStock ? (
            <div className="list-cover-overlay">库存不足</div>
          ) : null}
        </div>
        <div className="list-right">
          <Link className="list-name" to={goodsPath}>
            {goods_name}
          </Link>
          <div className="list-action">
            <PriceTag type="red" tag="换" price={goods_price} />
            <Button disabled={addBtnDisabled} onClick={onCartAdd}>
              加入购物车
            </Button>
          </div>
        </div>
      </div>

      {selected_list.length > 0 ? (
        <div className="list-selected">
          <div className="list-selected-items">
            {selected_list.map((goods: any) => (
              <div key={goods.goods_id} className="list-selected-item">
                <div className="list-selected-cover">
                  <img
                    className="list-selected-image"
                    alt=""
                    src={goods.goods_image}
                  />
                  <div className="list-selected-overlay">
                    {goods.goods_num}件
                  </div>
                </div>
                <div className="list-selected-name">{goods.goods_name}</div>
              </div>
            ))}
          </div>
          <div className="list-selected-btn" onClick={onSelectedDisplay}>
            <div>
              <div>查看</div>
              <div>已选</div>
              <div>商品</div>
            </div>
            <Icon type="right" size="xxs" />
          </div>
        </div>
      ) : null}
    </>
  )
}
