import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export type SetURLSearchParams = (
  nextSearchParams?: URLSearchParams,
  navigateOpts?: { replace?: boolean }
) => void

/**
 * 获取和设置 URLSearchParams
 */
export function useSearchParams(): [URLSearchParams, SetURLSearchParams] {
  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const history = useHistory()
  const setSearchParams = useCallback(
    (nextSearchParams, navigateOpts) => {
      const navigate = navigateOpts?.replace ? history.replace : history.push
      navigate({ search: `?${nextSearchParams.toString()}` })
    },
    [history]
  )

  return [searchParams, setSearchParams]
}
