import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import fetch, { RequestConfig } from '../utility/fetcher'

export type GetRequest = RequestConfig | null

interface ReturnType {
  flag: number
  data: any
  msg: string
  server_time: number
  type?: string
  cache?: string
  cache_record?: any
}

interface Return<Data, Error>
  extends Pick<SWRResponse<Data, Error>, 'isValidating' | 'error' | 'mutate'> {
  data: Data | undefined
  isLoading: boolean
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<Data, Error>, 'fallbackData'> {
  fallbackData?: Data
}

export function useRequest<Data = unknown, Error = ReturnType>(
  request: GetRequest,
  config: Config<Data, Error> = {}
): Return<Data, Error> {
  const { data, error, isValidating, mutate } = useSWR<Data, Error>(
    request && JSON.stringify(request),
    () => fetch<Data>(request!),
    config
  )

  return {
    data,
    error,
    isLoading: !data && !error && isValidating,
    isValidating,
    mutate,
  }
}
