import { useState, useEffect, useCallback, useRef } from 'react'
import qs from 'qs'
import { ShopInfo } from '../../components'
import { useQuery } from '../../hooks'
import handlePages from '../../utility/handlePages'
import { request } from '../../utility/request'
import {
  formatHotAreaHTML,
  setValidShopId,
  showForceLoginModal,
} from '../../utility/utils'
import './list.css'

export default function StableIndex(props) {
  const [PageIndex, setPageIndex] = useState(1)
  const [bannersVisible, setBannersVisible] = useState(false)
  const [banners, setBanners] = useState([])
  const [advBottom, setAdvBottom] = useState('')
  const [goodsList, setGoodsList] = useState([])
  const [shopId, setShopID] = useState(0)
  const [presentType] = useState(24)
  const [goodsType, setGoodsType] = useState([])
  const [noMore, setNoMore] = useState(false)
  const [shop_info, setShopInfo] = useState({})
  const [nowTime, setNowTime] = useState(0)

  const shopInfoRef = useRef(null)
  const [paddingTop, setPaddingTop] = useState(140)

  const query = useQuery()
  const appKey = query.get('app_key') || '' // APP端传递的key

  useEffect(() => {
    if (props.location.search.indexOf('shop_id') >= 0) {
      let arr = props.location.search.split('&')
      let shop_id = 0
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].indexOf('shop_id') >= 0) {
          shop_id = arr[i].split('=')[1]
        }
      }
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        props.history.replace('/?shop_id=' + shop_id)
      } else {
        setShopID(shop_id)
      }

      setValidShopId(shop_id)
    }
  }, [props.location.search, props.history])

  useEffect(() => {
    if (!shopId) {
      return
    }
    request({
      url: 'Shop.ShopInfo',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        AppKey: appKey,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setShopInfo(res.data)
        setValidShopId(res.data.shop_id)

        // layout 更新完毕后获取店铺信息的高度
        setTimeout(() => {
          setPaddingTop(shopInfoRef.current.getBoundingClientRect().height)
        }, 0)
      } else if (res.flag === 401) {
        showForceLoginModal(res.msg)
      }
    })
  }, [shopId, appKey])

  useEffect(() => {
    if (!shopId) {
      return
    }

    request({
      url: 'Selection.TodayRecommend',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        PageIndex,
        ClassID: presentType,
        AppKey: appKey,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data && res.data.length === 0) {
          if (PageIndex === 1) {
            setGoodsList(res.data)
          }
          setNoMore(true)
        } else if (res.data && res.data.length > 0) {
          if (PageIndex === 1) {
            setGoodsList([])
          }
          setGoodsList((prev) => prev.concat(res.data))
        }
      } else if (res.flag === 401) {
        showForceLoginModal(res.msg)
      }
    })
  }, [shopId, PageIndex, presentType, appKey])

  useEffect(() => {
    if (!shopId) {
      return
    }
    request({
      url: 'Selection.GetClass',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        AppKey: appKey,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setGoodsType(res.data)
        //setFinished(true);
      } else if (res.flag === 401) {
        showForceLoginModal(res.msg)
      }
    })
  }, [shopId, appKey])

  useEffect(() => {
    if (!shopId) {
      return
    }
    request({
      url: 'Selection.IndexAdv',
      method: 'POST',
      body: qs.stringify({
        ShopID: shopId,
        AdvType: 100,
        AppKey: appKey,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        setBannersVisible(res.data.show === '1' && res.data.seckill === 1)
        setBanners(res.data.adv_list)
        setAdvBottom(res.data.c_home_bottom)
      } else if (res.flag === 401) {
        showForceLoginModal(res.msg)
      }
    })
  }, [shopId, appKey])

  const handleScorll = useCallback(() => {
    let rest = handlePages()
    if (rest && !noMore) {
      if (Date.now() - nowTime > 1000) {
        //防抖
        setNowTime(Date.now())
        setPageIndex((n) => n + 1)
      }
    }
  }, [noMore, nowTime])
  useEffect(() => {
    window.addEventListener('scroll', handleScorll)
    window.addEventListener('touchmove', handleScorll)
    return () => {
      window.removeEventListener('scroll', handleScorll)
      window.removeEventListener('touchmove', handleScorll)
    }
  }, [handleScorll])

  return (
    <div style={{ minHeight: '100vh' }}>
      <div className="search-top-home" ref={shopInfoRef}>
        <ShopInfo
          hideRedirect
          contactDisabled
          style={{ padding: '0 12px' }}
          {...shop_info}
        />
        <div className="search-nav-leading">
          <div className="search-right" to="/search">
            <img
              src={require('../../images/home_search.png').default}
              style={{ width: 27, marginTop: 3 }}
              alt=""
            />
          </div>
          <ul className="navbox">
            <li className="class-tab">今日推荐</li>
            {goodsType.map((item, idx) => (
              <li key={item.class_id}>{item.class_name}</li>
            ))}
          </ul>
        </div>
      </div>
      <div style={{ paddingTop }}>
        {bannersVisible && banners?.[0]?.adv_content && (
          <div>
            <img
              src={banners[0].adv_content}
              alt=""
              style={{ width: '100%' }}
            />
          </div>
        )}
        {advBottom && (
          <div style={{ padding: '8px 8px 0' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: formatHotAreaHTML({
                  html: advBottom,
                  parentWidth: Math.min(window.innerWidth - 16, 768 - 16),
                  hrefFormatter: () => '',
                }),
              }}
              style={{ pointerEvents: 'none' }}
              onClick={(e) => e.preventDefault()}
            ></div>
          </div>
        )}
      </div>
      {goodsList && goodsList.length > 0 && (
        <ul className="goodsListWrap" key="ewff">
          {goodsList.map((dataItem) => (
            <li key={dataItem.goods_id}>
              <div
                style={{
                  height: 180,
                  overflow: 'hidden',
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                }}
              >
                <img
                  src={dataItem.goods_image}
                  style={{ width: '100%' }}
                  alt=""
                />
              </div>
              <p className="goods_name_p">{dataItem.goods_name}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ padding: '3px 6px', color: 'red', fontSize: 18 }}>
                  ￥{dataItem.goods_price}
                </p>
                {/* <span style={{ color: '#9C9C9C', fontSize: 12 }}>{dataItem.stock_begin_num}件起批</span> */}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
