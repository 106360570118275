import { useEffect } from 'react'

export function useBodyBackgroundColor(color: string) {
  useEffect(() => {
    document.body.style.setProperty('background-color', color, 'important')
    return () => {
      document.body.style.removeProperty('background-color')
    }
  }, [color])
}
