import { Icon } from 'antd'
import { useHistory } from 'react-router-dom'
import { useRequest } from '../../hooks/useRequest'
import { cTokenHandler } from '../../utility/token'
import './index.css'

export type OrderMsgType = {
  id: string
  title: string
  sub_title: string
  redirect: 1 | 0
}

export function OrderMsgBar() {
  const history = useHistory()
  const { data } = useRequest<{ list: OrderMsgType[] }>({
    Url: 'Order.OrderMsg',
    Key: cTokenHandler.getToken(),
  })

  if (!data || data.list.length === 0) {
    return null
  }

  const msg = data.list[0] // 只展示第一个提醒
  const jump = Number(msg.redirect) === 1

  return (
    <div
      className="order-msg"
      onClick={() => {
        if (jump) {
          history.push(`/order/msg?id=${msg.id}`)
        }
      }}
    >
      <Icon type="exclamation-circle" theme="filled" />
      <div className="order-msg-main">
        <div className="order-msg-title">{msg.title}</div>
        <div>{msg.sub_title}</div>
      </div>
      {jump && <Icon type="right" style={{ color: '#999' }} />}
    </div>
  )
}
