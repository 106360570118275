import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Badge, Toast, ActivityIndicator } from 'antd-mobile'
import qs from 'qs'
import { ContactModalButton } from '../../components/contact-modal-button'
import { OrderMsgBar } from '../../components/order-msg-bar'
import { MoreOrders } from '../../components'
import { OrderSearchBar } from '../../components/order-search-bar'
import { request, reLogin } from '../../utility/request'
import { showConfirm } from '../../utility/modal'
import { countdown } from '../../utility/countdown'
import handlePages from '../../utility/handlePages'
import { config } from '../../utility/config'
import orderCanceled from '../../images/branch/order-canceled.png'
import './order.css'

const orderStateMap = {
  0: 99,
  1: 10,
  2: 20,
  3: 30,
  4: 40,
  5: 0,
}

class OrderList extends React.Component {
  constructor(props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search)

    this.state = {
      refreshing: false,
      order_state: Number(searchParams.get('order_state') || 0),
      height: document.documentElement.clientHeight,
      list: [],
      pageIndex: 1,
      ifLoading: false,
      finished: false,
      isIos: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      hasMultiAccount: localStorage.getItem('has_multi_account') === '1', // 是否是多账号用户
      useMulti: searchParams.get('use_multi') === '1', // 是否使用多账号接口
      hasHistoryOrder: false,
      showMoreOrders: false,
    }
  }

  handleScorll = () => {
    if (handlePages()) {
      this.handleListRequest(2)
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.handleListRequest(1)
    window.addEventListener('scroll', this.handleScorll)
    window.addEventListener('touchmove', this.handleScorll)
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }

  componentDidUpdate(prevProps) {
    const prevSearchParams = new URLSearchParams(prevProps.location.search)
    const searchParams = new URLSearchParams(this.props.location.search)

    if (prevSearchParams.get('use_multi') !== searchParams.get('use_multi')) {
      this.setState(
        {
          useMulti: searchParams.get('use_multi') === '1',
        },
        () => {
          this.handleSwitchType(this.state.order_state)
        }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScorll)
    window.removeEventListener('touchmove', this.handleScorll)
  }

  handleListRequest = (requestType) => {
    if (this.state.ifLoading || this.state.finished) {
      return
    }
    this.setState({
      ifLoading: true,
    })
    let that = this,
      PageIdx = this.state.pageIndex,
      tempList = this.state.list,
      OrderState
    switch (this.state.order_state) {
      case 0:
        OrderState = 99
        break
      case 1:
        OrderState = 10
        break
      case 2:
        OrderState = 20
        break
      case 3:
        OrderState = 30
        break
      case 4:
        OrderState = 40
        break
      case 5:
        OrderState = 0
        break
      default:
        OrderState = 99
        break
    }

    const last = this.state.list.length - 1
    const lastData = this.state.list[last] || {}
    const last_add_time = lastData.add_time ? lastData.add_time : 0

    request({
      url: this.state.useMulti
        ? 'MultiMemberOrder.MemberOrderList'
        : 'Order.MemberOrderList',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || '',
        OrderState,
        PageIndex: PageIdx,
        last_add_time,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        let psyco = res.data.list ? res.data.list : []
        if (requestType === 1) {
          tempList = psyco
        } else {
          tempList = tempList.concat(psyco)
        }

        that.setState({
          list: tempList,
          pageIndex: res.data.list ? PageIdx + 1 : PageIdx,
          finished: res.data.list?.length < 10,
          ifLoading: false,
          hasHistoryOrder: res.data.query_history_order === 1,
          // 只在全部/已完成/已关闭标签页下显示
          showMoreOrders: [99, 40, 0].indexOf(OrderState) > -1,
        })
      } else if (res.flag === 401) {
        reLogin(() => {
          that.props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      } else if (res.flag === 420) {
        reLogin(() => {
          that.props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      } else {
        res.msg && Toast.fail(res.msg)
      }

      that.setState({
        ifLoading: false,
      })
    })
  }

  handleSwitchType = (index) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        order_state: index,
        pageIndex: 1,
        finished: false,
        list: [],
      },
      () => {
        this.handleListRequest(1)
      }
    )
  }

  freshUpdate = () => {
    let OrderState,
      that = this
    switch (this.state.order_state) {
      case 0:
        OrderState = 99
        break
      case 1:
        OrderState = 10
        break
      case 2:
        OrderState = 20
        break
      case 3:
        OrderState = 30
        break
      case 4:
        OrderState = 40
        break
      case 5:
        OrderState = 0
        break
      default:
        OrderState = 99
        break
    }

    request({
      url: this.state.useMulti
        ? 'MultiMemberOrder.MemberOrderList'
        : 'Order.MemberOrderList',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || '',
        OrderState,
        PageIndex: 1,
        pageSize: this.state.pageIndex * 10,
        last_add_time: 0,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        let psyco = res.data.list ? res.data.list : []

        that.setState({
          list: psyco,
        })
      } else if (res.flag === 401) {
        reLogin(() => {
          that.props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        })
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }

  // 过滤删除掉的item
  reconsiliation = (order_id_crypto) => {
    this.setState({
      list: this.state.list.filter(
        (item) => item.order_id_crypto !== order_id_crypto
      ),
    })
  }

  goPay = () => {
    window.location.href = '/pay'
  }

  renderContent = (tab) => (
    <div>
      {this.state.list &&
        this.state.list.map((item, idx) => (
          <OrderItem
            key={item.order_id_crypto}
            data={item}
            useMulti={this.state.useMulti}
            freshUpdate={() => this.freshUpdate()}
            reconsiliation={(order_id_crypto) =>
              this.reconsiliation(order_id_crypto)
            }
            goPay={() => this.goPay()}
          />
        ))}

      {!this.state.list && !this.state.ifLoading && (
        <div style={{ paddingTop: '40%', textAlign: 'center' }}>
          <img
            alt=""
            src={require('../../images/cart/no-orders.png').default}
            style={{ width: 100 }}
          />
          <h3 style={{ color: '#999999', marginTop: 20 }}>
            您还没有订单，先
            <Link to="/" style={{ color: '#44BAAE', fontWeight: 'bold' }}>
              去逛逛
            </Link>
            ，在下单吧
          </h3>
        </div>
      )}

      {this.state.finished &&
        !this.state.ifLoading &&
        (this.state.hasHistoryOrder && this.state.showMoreOrders ? (
          <div style={{ padding: '24px 0', textAlign: 'center' }}>
            {this.state.list.length === 0 ? '暂无数据' : '到底啦'}，点击
            <span
              style={{ fontWeight: 500, color: '#576b95', userSelect: 'none' }}
              onClick={() =>
                this.props.history.push(
                  `/order/history?order_state=${
                    orderStateMap[this.state.order_state] || ''
                  }&use_multi=${this.state.useMulti ? 1 : 0}`
                )
              }
            >
              查看更多订单
            </span>
          </div>
        ) : this.state.hasMultiAccount &&
          !this.state.useMulti &&
          this.state.showMoreOrders ? (
          <MoreOrders state={this.state.order_state} />
        ) : (
          <div style={{ padding: '24px 0', textAlign: 'center' }}>加载完毕</div>
        ))}
    </div>
  )

  render() {
    const tabs = [
      { title: <Badge>全部</Badge> },
      { title: <Badge>待付款</Badge> },
      { title: <Badge>待发货</Badge> },
      { title: <Badge>待收货</Badge> },
      { title: <Badge>已完成</Badge> },
      { title: <Badge>已关闭</Badge> },
    ]

    return (
      <div className="special-bar-wrap">
        <ActivityIndicator
          toast
          text="加载中"
          animating={this.state.ifLoading}
        />
        <Tabs
          tabs={tabs}
          initialPage={this.state.order_state}
          renderTabBar={(aprops) => (
            <>
              <OrderMsgBar />
              <OrderSearchBar useMulti={this.state.useMulti} />
              <Tabs.DefaultTabBar {...aprops} page={5} />
            </>
          )}
          tabBarActiveTextColor="#44BAAE"
          swipeable={false}
          prerenderingSiblingsNumber={false}
          destroyInactiveTab={true}
          tabBarUnderlineStyle={{ border: '1px solid #44BAAE' }}
          onChange={(_, index) => this.handleSwitchType(index)}
        >
          {this.renderContent}
        </Tabs>
      </div>
    )
  }
}

export const OrderItem = (props) => {
  const { data, useMulti } = props
  const [cdObj, setCdObj] = React.useState({ h: '00', m: '00', s: '00' })
  const [if_show, setShow] = React.useState(true)
  const [inputVal] = React.useState(data.order_code)
  const inputRef = React.useRef(null)

  const useMultiValue = useMulti ? 1 : 0
  const detailPath =
    Number(data.is_history_order) === 1
      ? `/order/detail?id=${data.order_id_crypto}&use_multi=${useMultiValue}`
      : `/order_detail?order_id=${data.order_id_crypto}&use_multi=${useMultiValue}`

  const cancelOrder = () => {
    showConfirm('', '确定取消吗？', () => {
      request({
        url: 'Order.CancelOrder',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token') || '',
          OrderIdCrypto: data.order_id_crypto,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success('取消成功', 1, props.freshUpdate())
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })
  }

  const deleteOrder = () => {
    showConfirm('', '确定要删除吗？', () => {
      request({
        url: 'Order.DeleteOrder',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token') || '',
          OrderIdCrypto: data.order_id_crypto,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success(
            '删除成功',
            1,
            props.reconsiliation(data.order_id_crypto)
          )
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })
  }

  const remindDelivery = () => {
    request({
      url: 'Order.RemindDelivery',
      method: 'POST',
      body: qs.stringify({
        Key: localStorage.getItem('c_token') || '',
        OrderIdCrypto: data.order_id_crypto,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        Toast.success('提醒成功', 1)
      } else {
        res.msg && Toast.fail(res.msg)
      }
    })
  }

  const orderConfirm = () =>
    showConfirm('', '确定确认收货吗？', () => {
      request({
        url: 'Order.ConfirmOrder',
        method: 'POST',
        body: qs.stringify({
          Key: localStorage.getItem('c_token') || '',
          OrderIdCrypto: data.order_id_crypto,
        }),
      }).then(function (res) {
        if (res.flag === 200) {
          Toast.success('成功', 1, () =>
            props.reconsiliation(data.order_id_crypto)
          )
        } else {
          res.msg && Toast.fail(res.msg)
        }
      })
    })

  const goPay = () => {
    localStorage.setItem('member_id', data.member_id)
    localStorage.setItem('pay_money', data.order_total)
    localStorage.setItem('pay_code', data.pay_code)
    props.goPay()
  }

  const doCopy = () => {
    setTimeout(() => {
      inputRef.current.select()
      document.execCommand('copy')
      Toast.success('', 1)
    }, 100)
  }

  React.useEffect(() => {
    let c = data.count_down
    setCdObj(countdown(c))
    c === 0 && setShow(false)

    const ctimer = setInterval(() => {
      // setCd(n => n -1);
      setCdObj(countdown(c--))
      if (c < 0) {
        clearInterval(ctimer)
        setCdObj({ h: '00', m: '00', s: '00' })
        setShow(false)
      }
    }, 1000)

    return () => {
      clearInterval(ctimer)
    }
  }, [data.count_down])

  return (
    <div className="order-item" key={data.order_id_crypto}>
      <input className="copy-input" defaultValue={inputVal} ref={inputRef} />

      <div
        style={{ color: '#999999' }}
        className="order-post-time order-item-padding"
      >
        <div style={{ display: 'flex' }}>
          <p>订单编号：{data.order_code}</p>
          <button className="copy-btn" onClick={doCopy}>
            复制
          </button>
        </div>
        <span style={{ color: '#C83B4C' }}>{data.order_status_text}</span>
      </div>
      <Link to={detailPath}>
        {data.order_goods &&
          data.order_goods.length > 0 &&
          data.order_goods.map((item, idx) => (
            <div className="order-img-box" key={idx}>
              {Number(data.order_state) !== 0 &&
                (Number(item.is_refund) === 1 ? (
                  <img
                    className="partable-post-icon"
                    src={orderCanceled}
                    alt=""
                  />
                ) : Number(item.shipping_state) === 1 ? (
                  <img
                    className="partable-post-icon"
                    src={`//${config.imageCdn}/activity/79734a8599c8e1e1cd5439d277f5d7fd.png?imageslim`}
                    alt=""
                  />
                ) : Number(item.shipping_state) === 2 ? (
                  <img
                    className="partable-post-icon"
                    src={`//${config.imageCdn}/activity/3523c5fcbb7649c6355c085384111cca.png?imageslim`}
                    alt=""
                  />
                ) : Number(item.shipping_state) === 3 ? (
                  <img
                    className="partable-post-icon"
                    src={`//${config.imageCdn}/activity/16e0f2657a8fa56caa9e50086f3feae5.png?imageslim`}
                    alt=""
                  />
                ) : null)}

              <div className="order-goods-image">
                <img
                  src={item.goods_image}
                  alt=""
                  style={
                    +item.order_goods_type === 2
                      ? { width: '70%', height: '70%' }
                      : null
                  }
                />
              </div>
              <div className="order-goods-name">
                <h3>
                  {+item.order_goods_type === 2 ? (
                    <img
                      className="exchange-icon"
                      alt=""
                      src={item.exchange_ico}
                    />
                  ) : null}
                  {item.goods_name}
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: '#969696' }}>{item.goods_spec}</div>
                  <div style={{ display: 'flex' }}>
                    <p>
                      <span
                        style={{
                          color: '#C83B4C',
                          fontSize: 16,
                          fontWeight: 'bold',
                        }}
                      >
                        ￥{item.goods_price}
                      </span>
                      <span>/件</span>
                    </p>
                    <span style={{ fontSize: 16 }}>×{item.goods_num}</span>
                  </div>
                </div>
                <p
                  className={
                    Number(item.pre_sale) === 2
                      ? 'theme-color'
                      : 'theme-yellow-color'
                  }
                >
                  {item.pre_sale_tip}
                </p>
              </div>
            </div>
          ))}
        {(data.gift_list || []).map((item, idx) => (
          <div key={idx} className="order-img-box">
            <div className="order-goods-image">
              <img src={item.goods_image} alt="" />
            </div>
            <div className="order-goods-name">
              <div style={{ fontSize: 14, color: '#666' }}>
                {item.goods_name}
              </div>
              <div style={{ color: '#969696' }}>{item.goods_spec}</div>
              <div style={{ display: 'flex' }}>
                <span></span>
                <span style={{ fontSize: 12 }}>×{item.goods_num}</span>
              </div>
            </div>
          </div>
        ))}
        <div className="order-item-bot order-item-padding">
          <div className="order-bottom">
            <p>收件人：{data.full_name}</p>
            <p style={{ color: '#969696' }}>下单时间：{data.meng_add_time}</p>
          </div>
          <p>
            共{data.goods_total_num}件商品 合计￥{data.order_total}(含运费￥
            {data.freight})
          </p>
        </div>
      </Link>

      <div className="order-item-bot order-item-padding">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%',
            paddingTop: 10,
          }}
        >
          <ContactModalButton
            shopId={data.shop_id}
            branchId={data.branch_id}
            orderCode={data.order_code}
          />
          <div className="order-item-bot-left">
            {parseInt(data.allow_cancel) === 1 && !useMulti && (
              <button
                className="btn-cancel"
                style={{ alignSelf: 'center' }}
                onClick={cancelOrder}
              >
                取消订单
              </button>
            )}
            {parseInt(data.order_state) === 10 && if_show && !useMulti && (
              <button
                onClick={goPay}
                className="btn-pay"
                style={{ alignSelf: 'center' }}
              >
                去支付{cdObj.h}:{cdObj.m}:{cdObj.s}
              </button>
            )}
            {parseInt(data.order_state) === 20 && !useMulti && (
              <button
                className="del-or-multi"
                style={{ alignSelf: 'center' }}
                onClick={remindDelivery}
              >
                提醒发货
              </button>
            )}
            {data.evaluate_upload_state === 1 && !useMulti && (
              <Link to={`/order_detail?order_id=${data.order_id_crypto}`}>
                <button
                  className="del-or-multi"
                  style={{ alignSelf: 'center' }}
                >
                  评价
                </button>
              </Link>
            )}
            {data.evaluate_upload_state === 2 && !useMulti && (
              <Link
                to="/commentlist"
                className="del-or-multi"
                style={{ borderRadius: 15, padding: '3px 10px' }}
              >
                查看评价
              </Link>
            )}
            {parseInt(data.order_state) === 30 && !useMulti && (
              <button
                className="btn-cancel"
                style={{ alignSelf: 'center' }}
                onClick={orderConfirm}
              >
                确认收货
              </button>
            )}
            {parseInt(data.order_state) === 0 && !useMulti && (
              <button
                className="del-or-multi"
                style={{ alignSelf: 'center' }}
                onClick={deleteOrder}
              >
                删除订单
              </button>
            )}
            {parseInt(data.show_express) === 1 && (
              <Link
                to={`/package-detail/${data.order_id_crypto}?use_multi=${
                  useMulti ? 1 : 0
                }`}
              >
                <button
                  className="del-or-multi"
                  style={{ alignSelf: 'center' }}
                >
                  物流查询
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderList
