import React, { useState, useEffect } from 'react'
import NavTopBar from '../public/navBar'
import { request, reLogin } from '../../utility/request'
import { config } from '../../utility/config'
import { _t } from '../../utility/track'
import qs from 'qs'
import './order.css'

export default function Logistics(props) {
  const {
    match: {
      params: { ship_code },
    },
  } = props
  const [logList, setLogList] = useState([])
  const [orderGoods, setOrderGoods] = useState()
  const [imgSrc, setImg] = useState('')
  const key = localStorage.getItem('c_token') || ''

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
    window.wx.error(function (res) {})

    _t('pageview')
  }, [])

  useEffect(() => {
    request({
      url: 'Order.ExpressQuery',
      method: 'POST',
      body: qs.stringify({
        Key: key,
        Type: 0,
        ShippingCode: ship_code,
      }),
    }).then(function (res) {
      if (res.flag === 200) {
        if (res.data.data) {
          setLogList(res.data.data)
          setOrderGoods(res.data.order_goods)
          setImg(
            `//${config.imageCdn}/activity/a71641d3c4b966c84099ac0747a1f701.png?imageslim`
          )
        }
      } else if (res.flag === 420 || res.flag === 401) {
        reLogin(() =>
          props.history.push(
            `/login?shop_id=${localStorage.getItem(
              'shop_id'
            )}&branch_id=${localStorage.getItem('branch_id')}`
          )
        )
      }
    })
  }, [ship_code, key, props.history])
  return (
    <div style={{ padding: '80px 0 60px 0', minHeight: '100vh' }}>
      <NavTopBar title={'物流查询'} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={imgSrc} alt="" style={{ width: 60, height: 50 }} />
        <div
          style={{
            textAlign: 'left',
            alignItems: 'center',
            padding: '7px 0 0 15px',
          }}
        >
          <p>{orderGoods && orderGoods.express_name}</p>
          <p>快递单号：{orderGoods && orderGoods.shipping_code}</p>
        </div>
      </div>
      <ul className="steps-wrap">
        {logList && logList.length > 0 ? (
          <>
            <div className="sector-line"></div>
            {logList.map((item, idx) => (
              <li key={idx}>
                <span>{item.time}</span>
                <div className={'step-circle step-plaform'}></div>
                <p>{item.context}</p>
              </li>
            ))}
          </>
        ) : (
          <p style={{ marginTop: 60, textAlign: 'center' }}>
            暂无物流信息~请稍后查询
          </p>
        )}
      </ul>
    </div>
  )
}
