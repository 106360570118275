import clsx from 'clsx'

import orderPartialDelivered from '../../images/branch/order-partial-delivered.png'
import orderDelivered from '../../images/branch/order-delivered.png'
import orderNotDelivered from '../../images/branch/order-not-delivered.png'
import orderCanceled from '../../images/branch/order-canceled.png'
import styles from './index.module.css'

export type OrderGoodsProps = {
  /**
   * 商品信息
   */
  goods: {
    goods_image: string
    goods_name: string
    goods_spec?: string
    goods_price?: string
    goods_num?: string
    goods_total?: string
    send_num?: string
    freight?: string
    profit?: string
    is_cancel_order?: string | null
    is_refund?: number
    shipping_state?: number
  }
  /**
   * 价格是否标红
   */
  isPriceRed?: boolean
  /**
   * 是否只展示商品图片和名称
   */
  simple?: boolean
  /**
   * 是否展示规格
   */
  showSpec?: boolean
  /**
   * 是否展示利润
   */
  showProfit?: boolean
  /**
   * 订单状态
   */
  orderState?: string
  /**
   * 是否隐藏背景图
   */
  hideBg?: boolean
}

export function OrderGoods({
  goods,
  isPriceRed,
  simple = false,
  showSpec = false,
  showProfit = false,
  orderState,
  hideBg = false,
}: OrderGoodsProps) {
  const isSpecPrefixed = goods.goods_spec?.startsWith('规格')
  const specText = isSpecPrefixed
    ? goods.goods_spec
    : `规格：${goods.goods_spec}`

  let bg = null

  if (Number(goods?.is_refund) === 1) {
    bg = orderCanceled
  } else {
    if (Number(goods?.shipping_state) === 1) {
      bg = orderNotDelivered
    } else if (Number(goods?.shipping_state) === 2) {
      bg = orderDelivered
    } else if (Number(goods?.shipping_state) === 3) {
      bg = orderPartialDelivered
    }
  }

  const descStyle =
    bg && !hideBg ? { backgroundImage: `url(${bg})` } : undefined

  return (
    <div className={styles.goods}>
      <img
        className={clsx(
          styles.goods__image,
          simple && styles['goods__image--simple']
        )}
        src={goods.goods_image}
        alt="商品图片"
      />
      <div className={styles.goods__desc} style={descStyle}>
        <div className={styles.goods__name}>{goods.goods_name}</div>
        {showProfit ? (
          <div>
            <div className={styles.goods__profit}>
              <span className={styles['goods__profit--left']}>{specText}</span>
              <span className={styles['goods__profit--right']}>
                ¥{goods.goods_total}
              </span>
            </div>
            <div className={styles.goods__profit}>
              <span className={styles['goods__profit--left']}>
                快递费：¥{goods.freight}&nbsp;&nbsp;&nbsp;数量：
                {goods.goods_num}
              </span>
              <span className={styles['goods__profit--right']}>
                利润：¥{goods.profit}
              </span>
            </div>
          </div>
        ) : !simple ? (
          <>
            <div className={styles.goods__spec}>{specText}</div>
            <div className={styles.goods__misc}>
              <span
                className={clsx(
                  styles.goods__price,
                  isPriceRed && styles['goods__price--red']
                )}
              >
                ¥{goods.goods_price}
              </span>
              <span className={styles.goods__num}>x{goods.goods_num}</span>
            </div>
          </>
        ) : showSpec && goods.goods_spec ? (
          <>
            <div className={styles.goods__spec}>{specText}</div>
            <div></div>
          </>
        ) : null}
      </div>
    </div>
  )
}
