import { useEffect, useMemo } from 'react'
import { useQuery } from '../../hooks/useQuery'
import { useRequest } from '../../hooks/useRequest'

declare global {
  interface Window {
    webkit?: any
  }
}

type Area = {
  id: string
  name: string
  children: Area[]
}

function mergeAreaList(list: Array<[Area, Area?, Area?]>) {
  const mergedList: Area[] = []

  list.forEach((item) => {
    const province = mergedList.find((province) => province.id === item[0].id)

    if (province) {
      const city = province.children.find((city) => city.id === item[1]?.id)

      if (city && item[2]) {
        city.children.push(item[2])
      } else if (item[1]) {
        province.children.push({
          ...item[1],
          children: item[2] ? [item[2]] : [],
        })
      }
    } else {
      mergedList.push({
        ...item[0],
        children: item[1]
          ? [{ ...item[1], children: item[2] ? [item[2]] : [] }]
          : [],
      })
    }
  })

  return mergedList
}

function OrderMsg() {
  const query = useQuery()
  const { data } = useRequest<any>({
    Url: 'Order.GetMsgInfo',
    ShopID: localStorage.getItem('shop_id') || '',
    Key: localStorage.getItem('c_token') || '',
    Id: query.get('id'),
  })

  const html = data ? data.content.trim().replace(/\n/g, '<br>') : ''
  const merged = useMemo(() => mergeAreaList(data?.area_list || []), [data])

  useEffect(() => {
    const prevTitle = document.title

    document.title = '延迟发货说明'

    // iOS APP 需要主动触发标题更新
    // FIXME: 循环设置，防止 APP 默认设置响应较慢，下个版本使用 yj_backend/frontend
    setInterval(() => {
      window.webkit?.messageHandlers?.setTitle?.postMessage('延迟发货说明')
    }, 1000)

    return () => {
      document.title = prevTitle
    }
  }, [])

  return (
    <div style={{ padding: 16, color: '#333' }}>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
      {merged.length > 0 && (
        <>
          <div style={{ margin: '16px 0' }}>以下是此次延迟地区明细：</div>
          {merged.map((province) => (
            <div
              key={province.id}
              style={{
                marginBottom: 12,
                paddingBottom: 12,
                borderBottom: '1px solid #eee',
              }}
            >
              <h3 style={{ lineHeight: 2 }}>{province.name}</h3>
              <div style={{ fontSize: 13 }}>
                {province.children.length === 0
                  ? '全省/市/区'
                  : province.children
                      .map(
                        (city) =>
                          city.name +
                          (city.children.length === 0
                            ? ''
                            : `（${city.children
                                .map((area) => area.name)
                                .join('、')}）`)
                      )
                      .join('、')}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default OrderMsg
