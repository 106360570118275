/**
 * 创建一个令牌处理器对象，提供获取、设置和删除令牌的方法
 * @param tokenKey 用于在本地存储中存储令牌的键
 * @returns 返回一个对象，这个对象有以下方法：
 * - `getToken`: 获取令牌，如果没有令牌，返回空字符串
 * - `setToken`: 设置令牌，接受一个字符串作为参数，将这个字符串作为令牌存储在本地存储中
 * - `removeToken`: 删除令牌，从本地存储中删除令牌
 */
function createTokenHandler(tokenKey: string) {
  return {
    key: tokenKey,
    getToken() {
      return localStorage.getItem(tokenKey) || ''
    },
    setToken(token: string) {
      localStorage.setItem(tokenKey, token)
    },
    removeToken() {
      localStorage.removeItem(tokenKey)
    },
  }
}

// C端用户token
export const cTokenHandler = createTokenHandler('c_token')

// 分店店主token
export const branchTokenHandler = createTokenHandler('branch_token')
