import React, { Fragment } from 'react'
import { config } from '../../utility/config'
import './index.css'

const ICON_URL = `//${config.imageCdn}/member_levelico/non-delivery.png`

export type Area = {
  name: string
  sub: any[]
}

export type NonDeliveryProps = {
  list: Area[]
}

/**
 * 不可发货地区组件
 */
export function NonDelivery({ list }: NonDeliveryProps) {
  return (
    <div className="container">
      {list.length === 0 && <div>暂无数据</div>}
      {list.map((province, index) => (
        <div key={index} className="province">
          <img className="province-icon" src={ICON_URL} alt="" />
          <div className="province-content">
            {/* 省份名称 */}
            <div className="province-name">{province.name}</div>
            <div className="cities">
              {province.sub.length
                ? province.sub.map((city, index) => (
                    <Fragment key={index}>
                      {/* 城市名称 */}
                      <span>{city.name}</span>
                      {/* 地区 */}
                      {city.sub.length ? (
                        <span className="areas">
                          （{city.sub.map((item: Area) => item.name).join('、')}
                          ）
                        </span>
                      ) : null}
                      {/* 最后一个市不添加顿号 */}
                      {index !== province.sub.length - 1 ? '、' : null}
                    </Fragment>
                  ))
                : '全省/市/区'}
            </div>
            {/* 最后一个省份不添加分割线 */}
            {index !== list.length - 1 ? <div className="line"></div> : null}
          </div>
        </div>
      ))}
    </div>
  )
}
