import { Suspense } from 'react'
import { Route, Switch, BrowserRouter, withRouter } from 'react-router-dom'
import ScrollRestoration from 'react-scroll-restoration'

import { Loading } from '../components/loading'
import { routes } from '../utility/routers'
import { useShouldReload } from '../hooks'

const ContentDetail = (props) => {
  return (
    <Switch>
      {routes.map((item, idx) => (
        <Route key={idx} {...props} path={item.path} component={item.comp} />
      ))}
    </Switch>
  )
}

const WithRouterContentDetail = withRouter(ContentDetail)

export default function ContentWrapper(props) {
  const { isReloading } = useShouldReload()

  if (isReloading) return null

  return (
    <BrowserRouter>
      <ScrollRestoration />
      <Suspense fallback={<Loading />}>
        <WithRouterContentDetail {...props} />
      </Suspense>
    </BrowserRouter>
  )
}
