import fetch from '../utility/fetcher'

export type LianlianPayInfo = {
  pay_code: string
  order_total: string
  lian_trade_no: string
  wxpay_trade_no: string
  order_type: 'pc_recharge' | 'c_order_pay'
}

/**
 * 根据连连单号获取连连支付信息
 */
export function getLianlianPayInfo(subChnlNo: string) {
  return fetch<LianlianPayInfo>({
    Url: 'LianlianPay.QueryOrder',
    SubChnlNo: subChnlNo,
  })
}
