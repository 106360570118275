import { useEffect } from 'react'
import { ActivityIndicator } from 'antd-mobile'
import { useErrorResponse, useToken } from '../../hooks'
import { useQuery } from '../../hooks/useQuery'
import { useRequestInfinite } from '../../hooks/useRequestInfinite'
import { useReachEnd } from '../../hooks/useReachEnd'
import { config } from '../../utility/config'
import { _t } from '../../utility/track'

function Evaluate() {
  // 获取 query 参数
  const query = useQuery()
  const goods_id = query.get('goods_id')
  const eva_count = query.get('eva_count')

  const [token] = useToken()
  // 买家秀列表API请求
  const {
    list: commentList,
    isLoading,
    isLoadingMore,
    isReachingEnd,
    loadMore,
    error,
  } = useRequestInfinite((PageIndex) => ({
    Url: 'Selection.GoodsEvaluateList',
    ShopID: localStorage.getItem('shop_id') || '',
    Key: token,
    GoodsID: goods_id,
    PageIndex,
  }))

  useErrorResponse(error)

  // 到达底部页数+1
  useReachEnd(() => {
    if (isLoadingMore || isReachingEnd) return

    loadMore()
  })

  useEffect(() => {
    // 隐藏微信分享
    window.wx.ready(() => {
      window.wx.hideOptionMenu()
    })

    _t('pageview')
  }, [])

  // 微信预览图片
  const previewImgs = (current: string, urls: string[]) => {
    window.wx.previewImage?.({
      current: current, // 当前显示图片的http链接
      urls, // 需要预览的图片http链接列表
    })
  }

  return (
    <div className="evaluate-page">
      <h3 style={{ paddingLeft: 10 }}>全部评论(共{eva_count}条)</h3>
      {isLoading ? (
        <ActivityIndicator toast animating text="稍等哦~" />
      ) : (
        <>
          <ul>
            {commentList.map((item: any, idx) => (
              <li
                style={{
                  borderBottom: '1px solid #f4f4f4',
                  padding: '0 10px 10px 10px',
                }}
                key={idx}
              >
                <div className="row-line-box" style={{ border: 'none' }}>
                  <div style={{ display: 'flex' }}>
                    <img
                      src={`//${config.imageCdn}/activity/b4a18d33e13be6d08006470b7f0a8d1c.jpeg?imageslim`}
                      style={{ width: 35, height: 35, borderRadius: '50%' }}
                      alt=""
                    />
                    <span
                      style={{
                        color: '#969696',
                        alignSelf: 'center',
                        marginLeft: 6,
                      }}
                    >
                      匿 名
                    </span>
                  </div>
                  <span style={{ color: '#969696' }}>{item.add_times}</span>
                </div>
                <p>{item.eva_content}</p>
                <div style={{ width: '100%', overflowX: 'scroll' }}>
                  <ul className="comment-photo">
                    {item.eva_image &&
                      item.eva_image.map((src: any, ix: number) => (
                        <li
                          key={ix}
                          onClick={() => previewImgs(src, item.eva_image)}
                        >
                          <img src={src} alt="" />
                        </li>
                      ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>

          <div className="indicator">
            {isLoadingMore && <ActivityIndicator text="加载中..." />}
            {isReachingEnd && '到底咯~'}
          </div>
        </>
      )}
    </div>
  )
}

export default Evaluate
