import { useState } from 'react'
import { ContactModal } from '../contact-modal'
import icon from '../../images/kf.png'
import './index.css'

export type ContactModalButtonProps = {
  shopId: string
  branchId?: string
  orderCode?: string
  disabled?: boolean
  showIcon?: boolean
}

export function ContactModalButton({
  shopId,
  branchId,
  orderCode,
  disabled,
  showIcon = true,
}: ContactModalButtonProps) {
  const [modalVisible, setModalVisible] = useState(false)

  function handleButtonClick() {
    if (disabled) return

    setModalVisible(true)
  }

  return (
    <>
      <div className="contact-modal-button" onClick={handleButtonClick}>
        {showIcon ? (
          <img
            className="contact-modal-button__icon"
            src={icon}
            alt="客服图标"
          />
        ) : null}
        <span>联系店主</span>
      </div>

      <ContactModal
        shopId={shopId}
        branchId={branchId}
        orderCode={orderCode}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}
