import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import './index.css'

export type MoreOrdersProps = {
  className?: string
  state?: number
  linkProps?: LinkProps
}

export function MoreOrders({
  className,
  state = 0,
  linkProps,
}: MoreOrdersProps) {
  return (
    <div className={clsx('more-orders', className)}>
      <span>找不到订单？试试查看</span>
      <Link
        className="more-orders__link"
        to={`/order?order_state=${state}&use_multi=1`}
        {...linkProps}
      >
        更多订单
      </Link>
    </div>
  )
}
