import { useEffect } from 'react'
import { Toast } from 'antd-mobile'

export type WxOpenLaunchAppProps = {
  appId: string
  extInfo?: string
  children: React.ReactNode
}

/**
 * App跳转组件
 * @see https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_Open_Tag.html#22
 */
export function WxOpenLaunchApp({
  appId,
  extInfo,
  children,
}: WxOpenLaunchAppProps) {
  useEffect(() => {
    const btn = document.getElementById('launch-btn') as HTMLElement

    function handleLaunchAppError(e: any) {
      let msg = '跳转失败'

      if (e.detail.errMsg === 'launch:fail') {
        msg = '请先安装App'
      } else if (e.detail.errMsg === 'launch:fail_check fail') {
        msg = '权限校验失败，请重试'
      }

      Toast.info(msg, 3, undefined, false)
    }

    btn.addEventListener('error', handleLaunchAppError)

    return () => {
      btn.removeEventListener('error', handleLaunchAppError)
    }
  }, [])

  return (
    <wx-open-launch-app id="launch-btn" appid={appId} extinfo={extInfo}>
      <script type="text/wxtag-template">{children}</script>
    </wx-open-launch-app>
  )
}
