import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import { useSearchParams } from '../hooks/useSearchParams'

const SHOP_ID_KEY = 'shop_id'
const BRANCH_ID_KEY = 'branch_id'

/**
 * 判断是否是有效的 id（32位16进制字符串）
 * @param id
 * @returns {boolean}
 */
function isValid(id: unknown): id is string {
  return typeof id === 'string' && /^[0-9a-f]{32}$/.test(id)
}

export type UseShopIdOptions = {
  /**
   * 是否因为链接上没有 branch_id 而删除本地的 branch_id
   */
  removeBranchId?: boolean
}

/**
 * 获取 shop_id 和 branch_id
 * @desc 优先级：url > localStorage
 * @param options {UseShopIdOptions} 配置项
 */
export function useShopId(
  options: UseShopIdOptions = { removeBranchId: false }
) {
  const [searchParams] = useSearchParams()
  const urlShopId = searchParams.get(SHOP_ID_KEY)
  const urlBranchId = searchParams.get(BRANCH_ID_KEY)

  const [localShopId, setLocalShopId] = useLocalStorage(
    SHOP_ID_KEY,
    isValid(urlShopId) ? urlShopId : '',
    { raw: true }
  )
  const [localBranchId, setLocalBranchId, removeLocalBranchId] =
    useLocalStorage(BRANCH_ID_KEY, isValid(urlBranchId) ? urlBranchId : '', {
      raw: true,
    })

  const shopId = isValid(urlShopId) ? urlShopId : localShopId || ''
  const branchId = isValid(urlBranchId) ? urlBranchId : localBranchId || ''

  useEffect(() => {
    if (isValid(urlShopId) && urlShopId !== localShopId) {
      setLocalShopId(urlShopId)
    }
  }, [urlShopId, localShopId, setLocalShopId])

  useEffect(() => {
    if (options?.removeBranchId && !urlBranchId) {
      removeLocalBranchId()
    } else if (isValid(urlBranchId) && urlBranchId !== localBranchId) {
      setLocalBranchId(urlBranchId)
    }
  }, [
    options?.removeBranchId,
    urlBranchId,
    localBranchId,
    setLocalBranchId,
    removeLocalBranchId,
  ])

  return {
    shopId,
    branchId: options?.removeBranchId && !urlBranchId ? '' : branchId,
    setShopId: setLocalShopId,
    setBranchId: setLocalBranchId,
    removeBranchId: removeLocalBranchId,
  }
}
