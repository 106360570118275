import { useEffect, useState } from 'react'

export function useIsMiniprogram() {
  const [isMiniprogram, setIsMiniprogram] = useState(false)

  useEffect(() => {
    window.wx.miniProgram?.getEnv((res: any) => {
      if (res?.miniprogram) {
        setIsMiniprogram(true)
      }
    })
  }, [])

  return isMiniprogram
}
