import { cRequest } from '../utility/http-instance'
import { cTokenHandler } from '../utility/token'

type RemovedGoodsDetail = {
  image_list: Array<{
    goods_image: string
  }>
  goods_name: string
  goods_amount: string
  shop_info: {
    shop_status: 0 | 1 // 1 展示进店逛逛按钮
    shop_logo: string
    shop_name: string
    shop_desc: string
    solid_shop_ico: string
    old_shop_ico: string
  }
  evaluation_count: string
  button_str: string
}

/**
 * 获取已下架商品详情
 */
export function getRemovedGoodsDetail(ogId: string) {
  return cRequest.post<RemovedGoodsDetail>('Selection.GoodsDetailsIsRemoved', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OgIdCrypto: ogId,
  })
}

type GoodsReviewItem = {
  eva_membername: string
  eva_content: string
}

/**
 * 获取商品评价
 */
export function getGoodsReviewList(goodsId: string) {
  return cRequest.post<Array<GoodsReviewItem>>('Selection.GoodsEvaluateList', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    GoodsID: goodsId,
  })
}

/**
 * 根据 OgId 获取信息
 */
export function getGoodsInfo(ogId: string) {
  return cRequest.post<{ after_sale_hint?: string }>('Selection.GoodsInfo', {
    OgIdCrypto: ogId,
  })
}
