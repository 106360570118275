import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActivityIndicator } from 'antd-mobile'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import { BackTop, Button, MoreOrders } from '../../components'
import { OrderItem } from '../my/orderList'
import {
  useMatchDelete,
  useQuery,
  useReachEnd,
  useTitle,
  useWechatHideAllNonBaseMenuItem,
} from '../../hooks'
import type { OrderItemType } from '../../api/order'
import { getMultiMemberOrderList, getOrderList } from '../../api/order'
import emptyUrl from '../../images/empty/list.png'
import { initialState } from './search'
import './search-result.css'

const pageSize = 10

export default function OrderSearchResult() {
  const [deletedIds, setDeletedIds] = useState<string[]>([])
  const matchDelete = useMatchDelete()

  const history = useHistory()
  const query = useQuery()
  const useMulti = query.get('use_multi') === '1' // 是否使用多账号接口

  const hasMultiAccount = localStorage.getItem('has_multi_account') === '1' // 是否是多账号用户
  const showMoreOrders = hasMultiAccount && !useMulti // 多帐号用户显示查看更多订单

  const { data: searchParams } = useSWR('/order/search', {
    fetcher: undefined,
    fallbackData: initialState,
  })
  const getListApi = useMulti ? getMultiMemberOrderList : getOrderList
  const { data, size, setSize, isLoading, isValidating, mutate } =
    useSWRInfinite(
      (index) => [
        useMulti
          ? 'MultiMemberOrder.MemberHistoryOrderList'
          : 'Order.MemberHistoryOrderList',
        {
          UserName: searchParams?.UserName,
          UserPhone: searchParams?.UserPhone,
          OrderCode: searchParams?.OrderCode,
          GoodsName: searchParams?.GoodsName,
        },
        index + 1,
      ],
      ([_, params, pageIndex]) => getListApi('99', params, pageIndex),
      { revalidateFirstPage: false }
    )

  const list = data
    ? ([] as OrderItemType[]).concat(...data.map((item) => item.list))
    : []
  const hasHistoryOrder = data?.[data.length - 1]?.query_history_order === 1
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.list.length === 0
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.list.length < pageSize)
  const renderList = list.filter(
    (item: any) => deletedIds.indexOf(item.order_id_crypto) === -1
  )

  useTitle('查询结果')
  useWechatHideAllNonBaseMenuItem()
  useReachEnd(
    () => {
      if (renderList.length === 0 || isLoadingMore || isReachingEnd) return

      setSize(size + 1)
    },
    { threshold: 250 }
  )

  function reload() {
    setSize(1)
    matchDelete(
      useMulti ? /MultiMemberOrder\.MemberOrderList/ : /Order\.MemberOrderList/
    )
    mutate()
  }

  return (
    <div className="search-result">
      {renderList.length === 0 && isValidating && (
        <ActivityIndicator text="加载中..." toast />
      )}

      {renderList.length === 0 && !isLoading && (
        <div className="search-result__empty">
          <img width="225" height="180" src={emptyUrl} alt="暂无数据" />
          {hasHistoryOrder ? (
            <div>
              暂无数据，点击
              <span
                style={{
                  fontWeight: 500,
                  color: '#576b95',
                  userSelect: 'none',
                }}
                onClick={() =>
                  history.push(`/order/history?use_multi=${useMulti ? 1 : 0}`)
                }
              >
                查看更多订单
              </span>
            </div>
          ) : showMoreOrders ? (
            <MoreOrders
              linkProps={{
                to: `/order/search-result?use_multi=1`,
                replace: true,
              }}
            />
          ) : (
            <>
              <div className="search-result__empty__text">暂无数据</div>
              <Button type="primary" onClick={() => mutate()}>
                点击重新加载
              </Button>
            </>
          )}
        </div>
      )}

      {renderList.map((item: any) => (
        <OrderItem
          key={item.order_id_crypto}
          data={item}
          useMulti={useMulti}
          freshUpdate={reload}
          reconsiliation={(id: string) => setDeletedIds([...deletedIds, id])}
          goPay={() => (window.location.href = '/pay')}
        />
      ))}
      {renderList.length !== 0 && (
        <div className="indicator">
          {isLoadingMore && <ActivityIndicator text="加载中..." />}
          {isReachingEnd &&
            (hasHistoryOrder ? (
              <div>
                到底啦，点击
                <span
                  style={{
                    fontWeight: 500,
                    color: '#576b95',
                    userSelect: 'none',
                  }}
                  onClick={() =>
                    history.push(`/order/history?use_multi=${useMulti ? 1 : 0}`)
                  }
                >
                  查看更多订单
                </span>
              </div>
            ) : showMoreOrders ? (
              <MoreOrders
                linkProps={{
                  to: `/order/search-result?use_multi=1`,
                  replace: true,
                }}
              />
            ) : (
              <span>到底咯~</span>
            ))}
        </div>
      )}

      <BackTop />
    </div>
  )
}
