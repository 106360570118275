import { FormEvent, useEffect, useState } from 'react'
import { InputItem, TextareaItem, Toast } from 'antd-mobile'
import { useHistory } from 'react-router-dom'

import { useQuery, useShopId } from '../../hooks'
import type { ShopMessageParams } from '../../api'
import { createShopMessage } from '../../api'
import './index.css'

let timer: ReturnType<typeof setTimeout>

export default function Message() {
  const history = useHistory()
  const query = useQuery()
  const { shopId } = useShopId()

  const [content, setContent] = useState('')
  const [orderCode, setOrderCode] = useState(query.get('order_code') || '')

  useEffect(() => {
    const title = document.title

    document.title = '留言反馈'

    return () => {
      document.title = title
      clearTimeout(timer)
    }
  }, [])

  // 提交留言
  function handleMessageSubmit(e: FormEvent) {
    e.preventDefault()

    if (!content) {
      Toast.info('请输入您要反馈的问题', 1.5, undefined, false)
      return
    }

    Toast.loading('提交中', 0, undefined, true)

    const data: Omit<ShopMessageParams, 'ShopID'> = {
      Content: content,
    }

    if (orderCode) {
      data.OrderCode = orderCode
    }

    createShopMessage({
      ShopID: shopId,
      ...data,
    })
      .then(() => {
        Toast.hide()
        Toast.success('您反馈的问题已受理', 3, undefined, true)
        timer = setTimeout(() => history.go(-1), 3000)
      })
      .catch(() => {
        Toast.hide()
        Toast.info('提交失败，请重试！', 1.5, undefined, false)
      })
  }

  return (
    <form className="message__form" onSubmit={handleMessageSubmit}>
      <div className="message__item message__item--content">
        <div>留言反馈</div>
        <TextareaItem
          placeholder="请输入您要反馈的问题"
          rows={5}
          count={1000}
          value={content}
          onChange={(v) => setContent(v as string)}
        />
      </div>
      <div className="message__item message__item--order-code">
        <div>订单编号</div>
        <InputItem
          className="message__order-code"
          placeholder="请输入订单编号（选填）"
          clear
          maxLength={20}
          value={orderCode}
          onChange={(v) => setOrderCode(v as string)}
        />
      </div>
      <div className="message__tip">
        <span className="message__tip--red">*</span>
        若反馈订单问题，前往订单列表，复制订单编号。
      </div>
      <button className="message__btn" form-type="submit">
        提交留言
      </button>
    </form>
  )
}
