import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ContactModalButton } from '../contact-modal-button'
import { ShowSimpleContent } from '../../utility/modal'
import { useShopId } from '../../hooks'
import './index.css'

export type ShopInfoProps = {
  shop_logo?: string
  shop_name?: string
  shop_desc?: string
  solid_shop_ico?: string
  old_shop_ico?: string

  style?: React.CSSProperties
  hideRedirect?: boolean
  hideContact?: boolean
  contactDisabled?: boolean

  [key: string]: any
}

export function ShopInfo(props: ShopInfoProps) {
  const {
    shop_logo,
    shop_name,
    shop_desc,
    solid_shop_ico,
    old_shop_ico,
    style,
    hideRedirect = false,
    hideContact = false,
    contactDisabled = false,
  } = props

  const { shopId, branchId } = useShopId()
  const [modalVisible, setModalVisible] = useState(false)

  function showFullDesc(e: any) {
    const { scrollHeight, clientHeight } = e.target

    // 判断是否需要显示描述详情
    if (scrollHeight <= clientHeight) return

    setModalVisible(true)
  }

  return (
    <div className="shop-info-container" style={style}>
      <img className="shop-info-logo" src={shop_logo} alt="" />
      <div className="shop-info-text">
        {shop_name && <div className="shop-info-name">{shop_name}</div>}
        {shop_desc && (
          <div className="shop-info-desc" onClick={showFullDesc}>
            {shop_desc}
          </div>
        )}
        {(solid_shop_ico || old_shop_ico) && (
          <div className="shop-info-icons">
            {/* 实体店图标 */}
            {solid_shop_ico && (
              <img
                className="shop-info-icon-solid"
                src={solid_shop_ico}
                alt=""
              />
            )}
            {/* 老店图标 */}
            {old_shop_ico && (
              <img className="shop-info-icon-old" src={old_shop_ico} alt="" />
            )}
          </div>
        )}
      </div>
      <div className="shop-info-contact">
        {hideRedirect ? null : (
          <Link to={`/?shop_id=${shopId}&branch_id=${branchId}`}>
            <div className="shop-info-redirect">进店逛逛</div>
          </Link>
        )}
        {hideContact ? null : (
          <ContactModalButton
            showIcon={false}
            disabled={contactDisabled}
            shopId={shopId}
            branchId={branchId}
          />
        )}
      </div>

      <ShowSimpleContent
        visible={modalVisible}
        handleCancel={() => setModalVisible(false)}
        formContent={shop_desc}
      />
    </div>
  )
}
