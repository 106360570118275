import { version } from '../utility/config'
import { cRequest } from '../utility/http-instance'
import { cTokenHandler } from '../utility/token'

export type OrderState = '0' | '10' | '20' | '30' | '40' | '99'

export type OrderGoodsType = {
  goods_id: string
  goods_image: string
  goods_name: string
  goods_spec: string
  goods_payprice: number
  goods_num: number
  og_id_crypto: string
  order_code: string
  order_goods_type: number
  is_refund: '0' | '1'
  is_cancel_order: number
  apply_type?: number | string
  r_goods_num: number
  send_num: string
  shipping_status: string
  exchange_ico: string
  goods_removed: 0 | 1
}

export type OrderGiftType = {
  goods_id: string
  goods_image: string
  goods_name: string
  goods_spec: string
  goods_num: number
}

export type OrderItemType = {
  order_id_crypto: string
  order_code: string
  order_state: OrderState
  order_status_text: string
  order_goods: Array<OrderGoodsType>
  gift_list: Array<OrderGiftType>
  full_name: string
  meng_add_time: string
  goods_total_num: string
  order_total: string
  freight: string
  shop_id: string
  branch_id?: string
}

export type OrderListResponse = {
  list: Array<OrderItemType>
  query_history_order: 0 | 1
}

export type OrderDetailType = {
  shop_id: string
  branch_id?: string
  order_code: string
  full_name: string
  user_phone: string
  user_area: string
  user_address: string
  order_goods: Array<OrderGoodsType>
  gift_list: Array<OrderGiftType>
  goods_total: string
  freight: string
  order_remark: string
  order_total: string
  add_time: number
  pay_time: number
  order_state: OrderState
}

/**
 * 获取订单列表
 *
 * @param orderState 订单状态
 * @param params 查询参数
 * @param pageIndex 页码
 */
export function getOrderList(
  orderState: OrderState,
  params: Record<string, unknown>,
  pageIndex: number
) {
  return cRequest.post<OrderListResponse>('Order.MemberOrderList', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OrderState: orderState,
    ...params,
    PageSize: 10,
    PageIndex: pageIndex,
    Version: version,
  })
}

/**
 * 获取历史订单列表
 *
 * @param orderState 订单状态
 * @param pageIndex 页码
 */
export function getHistoryOrderList(orderState: OrderState, pageIndex: number) {
  return cRequest.post<OrderListResponse>('Order.MemberHistoryOrderList', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OrderState: orderState,
    PageSize: 10,
    PageIndex: pageIndex,
    Version: version,
  })
}

/**
 * 获取历史订单详情
 *
 * @param orderId 订单ID
 */
export function getHistoryOrderDetail(orderId: string) {
  return cRequest.post<OrderDetailType>('Order.HistoryOrderDetail', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OrderIdCrypto: orderId,
    Version: version,
  })
}

/**
 * 获取多账号订单列表
 *
 * @param orderState 订单状态
 * @param params 查询参数
 * @param pageIndex 页码
 */
export function getMultiMemberOrderList(
  orderState: OrderState,
  params: Record<string, unknown>,
  pageIndex: number
) {
  return cRequest.post<OrderListResponse>('MultiMemberOrder.MemberOrderList', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OrderState: orderState,
    ...params,
    PageSize: 10,
    PageIndex: pageIndex,
    Version: version,
  })
}

/**
 * 获取多账号历史订单列表
 *
 * @param orderState 订单状态
 * @param pageIndex 页码
 */
export function getMultiMemberHistoryOrderList(
  orderState: OrderState,
  pageIndex: number
) {
  return cRequest.post<OrderListResponse>(
    'MultiMemberOrder.MemberHistoryOrderList',
    {
      Key: cTokenHandler.getToken(),
      ShopID: localStorage.getItem('shop_id') || '',
      OrderState: orderState,
      PageSize: 10,
      PageIndex: pageIndex,
      Version: version,
    }
  )
}

/**
 * 获取多账号历史订单详情
 *
 * @param orderId 订单ID
 */
export function getMultiMemberHistoryOrderDetail(orderId: string) {
  return cRequest.post<OrderDetailType>('MultiMemberOrder.HistoryOrderDetail', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
    OrderIdCrypto: orderId,
    Version: version,
  })
}
