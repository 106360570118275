import { cRequest } from '../utility/http-instance'
import { cTokenHandler } from '../utility/token'

/**
 * 获取购物车列表
 */
export function getCartList() {
  return cRequest.post<{ cart_count: number }>('MultiCart.Cartlist', {
    Key: cTokenHandler.getToken(),
    ShopID: localStorage.getItem('shop_id') || '',
  })
}
