import { useEffect } from 'react'

export type ReachEndFunction = () => void

export type ReachEndConfig = {
  threshold?: number
}

export function useReachEnd(fn: ReachEndFunction, config?: ReachEndConfig) {
  const { threshold = 100 } = config || {}

  useEffect(() => {
    function handleScroll() {
      // scrollTop 需要使用 fallback
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      const { clientHeight, scrollHeight } = document.documentElement

      if (scrollTop + clientHeight + threshold >= scrollHeight) {
        fn()
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('touchmove', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('touchmove', handleScroll)
    }
  }, [fn, threshold])
}
