import React from 'react'
import { ActivityIndicator } from 'antd-mobile'

export function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <ActivityIndicator size="large" />
    </div>
  )
}
